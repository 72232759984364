import React, { Suspense, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { CContainer } from '@coreui/react'
import Loading from "../views/widgets/Loading"

// routes config
import routes from '../routes'
import { useSelector } from 'react-redux'
import store from 'store'
import { useTranslation } from 'react-i18next';

const Setup = React.lazy(() => import('../views/user/Setup'));
const Admin = React.lazy(() => import('../views/administrators/Admin'));
const CMSBackend = React.lazy(() => import('../views/administrators/CMSBackend'));
const CMSFrontendWeb = React.lazy(() => import('../views/administrators/CMSFrontendWeb'));


const TheContent = () => {
  const { t } = useTranslation(['main']);
  const birth_info = useSelector(state => state.astro.charts.birthinfo)
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    let path = store.get('REDIRECT_PATH')
    store.remove('REDIRECT_PATH')
    if (path !== undefined && path !== '/setup') {
      console.log('found non-setup path to redirect to ' + path)
      store.remove('REDIRECT_PATH')
      //window.location.replace(window.location.origin + path)
    } else {
      console.log('path in storage = ' + path)
    }
  }, []);

  return (

    <CContainer fluid className='p-1 p-md-2 p-lg-3'>
      <Suspense fallback={<Loading color='dark' message='Calculating' />}>
        {birth_info && birth_info.complete === true ?
          <Routes>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={t(route.name)}
                  element={<route.component />}
                />
              )
            })}

            {user.isRootAdmin && <Route path='/admin/root' exact={true} name={'Admin'} element={(<Admin/>)} />}
            {user.isCMSAdmin && <Route path='/admin/cms/frontend' exact={true} name={'Frontend Web CMS'} element={<CMSFrontendWeb />} />}
            {user.isCMSAdmin && <Route path='/admin/cms/backend' exact={true} name={'Backend CMS'} element={<CMSBackend />} />}
            <Route path="/*" element={<Navigate to="dashboard" replace />} />
          </Routes>
          :
          <Routes>
            <Route path="/setup" element={<Setup />} />
            <Route path="*" element={<Navigate to="/setup" replace />} />
          </Routes>
        }
      </Suspense>
    </CContainer>

  )
}

export default React.memo(TheContent)

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import config from "./config/Config";


i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
  debug: false,
  fallbackLng: 'en',
  load: 'languageOnly',
  interpolation: {
    escapeValue: false
  },
  fallbackNS: 'main',
  defaultNS: 'main',
  ns: 'main',
  detection: {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  
    // keys or params to lookup language from
    lookupQuerystring: 'lang',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
  
    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  
    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,
  
    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' }
  },
  backend : {
    loadPath: config.cms.locales + '/{{lng}}/{{ns}}.json',
    crossDomain: true,
  },
  react: {
    useSuspense : true
  }
});
export default i18n;
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LocalDate from 'date-with-offset'
import './scss/style.scss';

import { Hub } from "aws-amplify";
import { useSelector, useDispatch } from 'react-redux'

import { authActions } from './store/actions/authActions'
import { chartActions } from './store/actions/chartActions'
import { locationActions } from './store/actions/locationActions'
import { calibrationActions } from "./store/actions/calibrationActions"
import { prefActions } from "./store/actions/prefActions"
import { billingActions } from "./store/actions/billingActions"

import Animate from "./views/widgets/Animate"
import ScrollToTop from "./views/widgets/ScrollToTop";

import { useTranslation, I18nextProvider } from 'react-i18next';
import config from "./config/Config";
import TheLayout from './containers/TheLayout';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: config.GTM
}

TagManager.initialize(tagManagerArgs)

// Pages
const Login = React.lazy(() => import('./pages/Login'));
const PublicChart = React.lazy(() => import('./pages/PublicPage'));

const App = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation();
  const lang = i18n.languages[0]

  const user = useSelector(state => state.auth.user)

  const billingStatus = useSelector(state => state.billing.billingStatus)

  const preferStoredLocation = () => dispatch(locationActions.preferStoredLocation())

  const calculate_transit_chart = (latitude, longitude, birthLatitude, birthLongitude, birthDate, birthName, address) => dispatch(chartActions.calculate_transit_chart(latitude, longitude, birthLatitude, birthLongitude, birthDate, birthName, address))
  const calculate_birth_chart = (latitude, longitude, date, name) => dispatch(chartActions.calculate_birth_chart(latitude, longitude, date, name))
  const getAllUserEvents = () => dispatch(calibrationActions.getAllUserEvents())
  const getUserBiodata = () => dispatch(calibrationActions.fetchBiodata())
  const processZodiacSurvey = (sv, QnA, rb) => dispatch(calibrationActions.processZodiacSurvey(sv, QnA, rb))

  const getCurrentUser = () => dispatch(authActions.getCurrentUser())

  const getChartInfo = (id) => dispatch(chartActions.getChartInfo(id))
  const getUserPreferences = () => dispatch(prefActions.getPreferences())

  const getBillingPrices = () => dispatch(billingActions.getPrices())
  const getBillingStatus = () => dispatch(billingActions.getStatus())

  const birth_info = useSelector(state => state.astro.charts.birthinfo)
  const birth_chart = useSelector(state => state.astro.charts.birth)
  const current_location = useSelector(state => state.location.current)
  const preferences = useSelector(state => state.preferences)


  useEffect(() => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          getCurrentUser()
          break;
        default:
        //do nothing
      }
    })
    getCurrentUser()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.authenticated === true) {
      preferStoredLocation()
      getChartInfo(user.subject)
      //make call to stripe to get pricing data
      getBillingStatus()
      getBillingPrices()
    }
  }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (billingStatus.isLoading === false && billingStatus.active !== undefined && billingStatus.active === false) {
      getBillingPrices()
    }
  }, [billingStatus]);// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    let transitLoop = undefined
    if (user.authenticated === true && birth_info.complete && current_location.longitude && current_location.latitude && lang) {
      const long = current_location.longitude
      const lat = current_location.latitude

      const birthLat = birth_info.location.latitude;
      const birthLong = birth_info.location.longitude;
      const birthDate = new LocalDate(
        parseInt(birth_info.date.year),
        parseInt(birth_info.date.month),
        parseInt(birth_info.date.day),
        parseInt(birth_info.time.hours24),
        parseInt(birth_info.time.minutes),
        parseInt(0),
        parseInt(0),
        parseInt(birth_info.time.offset)
      );
      const birthName = birth_info.firstName + ' ' + birth_info.lastName;

      calculate_transit_chart(lat, long, birthLat, birthLong, birthDate, birthName, JSON.stringify(current_location.location))
      getAllUserEvents()
      getUserBiodata()
      
      transitLoop = setInterval(() => {
        calculate_transit_chart(lat, long, birthLat, birthLong, birthDate, birthName, current_location.location)
      }, 600000)
    }
    return () => {
      if (transitLoop) clearInterval(transitLoop)
    };
    // eslint-disable-next-line
  }, [current_location, birth_info, lang, preferences.zodiacCalibration]);

  useEffect(() => {
    if (birth_info.complete && lang) {
      calculate_birth_chart(
        birth_info.location.latitude,
        birth_info.location.longitude,
        new LocalDate(
          parseInt(birth_info.date.year),
          parseInt(birth_info.date.month),
          parseInt(birth_info.date.day),
          parseInt(birth_info.time.hours24),
          parseInt(birth_info.time.minutes),
          parseInt(0),
          parseInt(0),
          parseInt(birth_info.time.offset)
        ),
        birth_info.firstName + ' ' + birth_info.lastName
      )

      getUserPreferences()
    }

    // eslint-disable-next-line
  }, [birth_info, lang]);

  useEffect(() => {
    if (birth_chart && birth_chart.D1 && birth_chart.D1.length > 0) {
      let av = JSON.parse(JSON.stringify(birth_chart.ashtakvarga.sarvashtakvarga))
      const one = birth_chart.D1.find(h => h.sign === 1).house
      const second = av.splice(one - 1)
      const newav = [...second, ...av]
      processZodiacSurvey(newav, undefined, true)
    }
    // eslint-disable-next-line
  }, [birth_chart]);

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ScrollToTop />
        {
          user.loading === true ? <Animate color='secondary' message='Calculating'/>
            :
            user.authenticated ?
              birth_info.isLoading === true ? <Animate color='primary' message='Initializing'/>
                :
                current_location.inProgress === true ? <Animate color='info' message='Calculating'/>
                  :
                  birth_chart.isLoading === true ? <Animate color='primary' message='Initializing' />
                    :
                      <React.Suspense fallback={<Animate color='primary' message='Initializing' />}>
                        <Routes>
                          <Route exact path="/public/chart/:id" name="Public Chart" element={<PublicChart />} />
                          <Route path="/*" element={<TheLayout />} />
                        </Routes>
                      </React.Suspense>
              :
              
                <Routes>
                  <Route exact path="/login" name="Login Page" element={<Login/>} />
                  <Route exact path="/public/chart/:id" name="Public Chart" element={<PublicChart/>} />
                  <Route path="/*" name="Login Page" element={<Login />} />
                </Routes>
              
        }
      </BrowserRouter>
    </I18nextProvider>
  );

}

export default App

import config from "../config/Config";
import { Auth } from "aws-amplify";
import axios from 'axios';
import LocalDate from 'date-with-offset';
import i18n from '../i18n';

const API = {

    getUserPhoto: async (data) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.post('/user/photo', data)
        return res.data;
    },

    getCrudeLocation: async () => {
        const i = axios.create({
            baseURL: config.apiGateway.URL,
        });
        const res = await i.get('/user/location/crude')
        return res.data;
    },

    getSurvey: async (id) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.get('/survey/' + id)
        return res.data;
    },

    processZodiacSurvey: async (sv, answer, rebuild) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const event = {
            answer: answer,
            sv: sv,
            rebuild: rebuild
        };
        const res = await i.post('/survey/zodiac', event, { params: { lang: i18n.languages[0] } })
        return res.data;
    },

    resetZodiacSurvey: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.delete('/survey/zodiac')
        return res.data;
    },

    getFineLocation: async (data) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.post('/user/location/fine', data)
        return res.data;
    },

    findLocationMatch: async (data) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.post('/user/chart/location', data)
        return res.data;
    },

    updateChartInfo: async (data) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.put('/user/chart/info', data)
        return res.data;
    },

    refreshChartInfo: async (data) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.put('/user/chart/info/refresh', data)
        return res.data;
    },

    getChartInfo: async (id, sal) => {
        const salt = () => {
            var pwd =
                (
                    Array(3).fill("0123456789").map(function (x) {
                        return x[Math.floor(Math.random() * x.length)];
                    }).join('') +
                    Array(3).fill("abcdefghijklmnopqrstuvwxyz").map(function (x) {
                        return x[Math.floor(Math.random() * x.length)];
                    }).join('')
                )
                    .split('').sort(function () {
                        return 0.5 - Math.random();
                    }).join('');

            return pwd;
        }

        if (sal === undefined) {
            id = salt() + '-' + id
        }

        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.get('/user/chart/info/' + id)
        return res.data;
    },

    getPublicChart: async (idWithSalt) => {
        const i = axios.create({
            baseURL: config.apiGateway.URL,
        });

        const res = await i.get('/public/chart/details/' + idWithSalt, { params: { lang: i18n.languages[0] } })
        return res.data;
    },

    getPublicStatus: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.get('/user/public/status')
        if (res.data && res.data.public === 'T') {
            return true
        } else {
            return false
        }
    },

    setPublicStatus: async (status) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.put('/user/public/status', { status: status === true ? 'T' : 'F' })
        return res.data;
    },

    getUserPreferences: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.get('/user/preferences')
        return res.data;
    },

    setUserPreferences: async (preferences) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.put('/user/preferences', { preferences })
        return res.data;
    },

    getFriendChart: async (saltedId) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.get('/user/social/chart/details/' + saltedId, { params: { lang: i18n.languages[0] } })
        return res.data;
    },

    addUserEvent: async (event) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.post('/user/event', event, { params: { lang: i18n.languages[0] } })
        return res.data;
    },

    deleteUserEvent: async (id) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.delete('/user/event/' + id)
        return res.data;
    },

    getAllUserEvents: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.get('/user/event/all')
        return res.data;
    },

    getUserBiodata: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.get('/user/biodata')
        return res.data;
    },

    saveUserBiodata: async (bio) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.post('/user/biodata', bio)
        return res.data;
    },

    getUserFriends: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.get('/user/friends')
        return res.data;
    },

    sendFriendRequest: async (obj) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.post('/user/friends/request', obj)
        return res.data;
    },

    acceptFriendRequest: async (request) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.post('/user/friends/accept', request)
        return res.data;
    },

    deleteUserFriend: async (obj) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.post('/user/friends/delete', obj)
        return res.data;
    },

    getUserAstrologers: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.get('/user/astrologers')
        return res.data;
    },

    processEmailContacts: async (list, autoSuggest) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const body = {}
        body.list = list
        if (autoSuggest === undefined || (autoSuggest !== true && autoSuggest !== false)) autoSuggest = false
        body.autoSuggest = autoSuggest

        const res = await i.post('/user/contacts/email', body)
        return res.data;
    },

    sendEmailInvite: async (email) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.post('/user/invite/email', { email })
        return res.data;
    },

    sendFeedback: async (subject, body) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.post('/user/feedback', { subject, body })
        return res.data;
    },

    authActivity: async (activity) => {
        const i = axios.create({
            baseURL: config.apiGateway.URL,
        });
        i.post('/profile/auth/activity', activity)
    },

    getNews: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.get('/news')
        return res.data;
    },

    getBirthChart: async (latitude, longitude, birthDate, name) => {

        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        var input = {};
        input.name = name;
        input.location = {};
        input.location.longitude = parseFloat(longitude);
        input.location.latitude = parseFloat(latitude);

        input.date = {};
        input.date.day = birthDate.getDate();
        input.date.month = birthDate.getMonth();
        input.date.year = birthDate.getFullYear();

        input.time = {};
        input.time.hours24 = birthDate.getHours();
        input.time.minutes = birthDate.getMinutes();
        input.time.offset = birthDate.offset();

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });

        const res = await i.post("/user/chart/details", input, { params: { lang: i18n.languages[0] } });

        res.data.metadata.rawdate = birthDate;
        res.data.dasha.forEach(md => {
            md.startDate = new Date(md.startDate);
            md.endDate = new Date(md.endDate);
            md.ad.forEach(ad => {
                ad.startDate = new Date(ad.startDate);
                ad.endDate = new Date(ad.endDate);
                ad.pad.forEach(pad => {
                    pad.startDate = new Date(pad.startDate);
                    pad.endDate = new Date(pad.endDate);
                }
                )
            }
            )
        });

        const sbMap = new Map(Object.entries(res.data.shadbala));
        res.data.shadbala = sbMap;

        const planetsMap = new Map(Object.entries(res.data.planets));
        res.data.planets = planetsMap;

        const signsMap = new Map(Object.entries(res.data.signs));
        const newSignsMap = new Map();
        signsMap.forEach(function (value, key) {
            const newKey = parseInt(key);
            newSignsMap.set(newKey, value);
        })
        res.data.signs = newSignsMap;

        return res.data;
    },

    getTransit: async (latitude, longitude, birthLatitude, birthLongitude, birthDate, birthName, address) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        var transitInfo = {};
        let now = new Date();
        transitInfo.name = 'transit';
        transitInfo.location = {};
        transitInfo.location.address = address;
        transitInfo.location.longitude = parseFloat(longitude);
        transitInfo.location.latitude = parseFloat(latitude);
        transitInfo.date = {};
        transitInfo.date.day = now.getDate();
        transitInfo.date.month = now.getMonth();
        transitInfo.date.year = now.getFullYear();
        transitInfo.time = {};
        transitInfo.time.hours24 = now.getHours();
        transitInfo.time.minutes = now.getMinutes();
        transitInfo.time.offset = (-1 * now.getTimezoneOffset());

        let birthInfo = {};
        birthInfo.location = {};
        birthInfo.location.longitude = parseFloat(birthLongitude);
        birthInfo.location.latitude = parseFloat(birthLatitude);
        birthInfo.date = {};
        birthInfo.date.day = birthDate.getDate();
        birthInfo.date.month = birthDate.getMonth();
        birthInfo.date.year = birthDate.getFullYear();
        birthInfo.time = {};
        birthInfo.time.hours24 = birthDate.getHours();
        birthInfo.time.minutes = birthDate.getMinutes();
        birthInfo.time.offset = (-1 * birthDate.getTimezoneOffset());
        let input = {};
        input.transitInfo = transitInfo;
        input.birthInfo = birthInfo;

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.post("/transit", input, { params: { lang: i18n.languages[0] } });

        res.data.metadata.rawdate = new LocalDate(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            now.getHours(),
            now.getMinutes(),
            now.getSeconds(),
            now.getMilliseconds(),
            -1 * now.getTimezoneOffset()
        );

        return res.data;
    },

    getPeriodAnalysis: async (current_latitude, current_longitude, birth_info, name, start, end) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const newStartDate = new Date(start);
        const newEndDate = new Date(end);

        let input = {};

        let startDate = {};
        startDate.day = newStartDate.getDate();
        startDate.month = newStartDate.getMonth();
        startDate.year = newStartDate.getFullYear();

        let endDate = {};
        endDate.day = newEndDate.getDate();
        endDate.month = newEndDate.getMonth();
        endDate.year = newEndDate.getFullYear();

        var transitInfo = {};
        let now = new Date();
        transitInfo.name = 'transit';
        transitInfo.location = {};
        transitInfo.location.longitude = parseFloat(current_longitude);
        transitInfo.location.latitude = parseFloat(current_latitude);
        transitInfo.date = {};
        transitInfo.date.day = now.getDate();
        transitInfo.date.month = now.getMonth();
        transitInfo.date.year = now.getFullYear();
        transitInfo.time = {};
        transitInfo.time.hours24 = now.getHours();
        transitInfo.time.minutes = now.getMinutes();
        transitInfo.time.offset = (-1 * now.getTimezoneOffset());

        input.birthInfo = birth_info;
        input.transitInfo = transitInfo;
        input.startDate = startDate;
        input.endDate = endDate;

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });
        const res = await i.post("/user/period/analysis", input, { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    getRectificationQuestions: async (latitude, longitude, birthDate, name) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        let input = {};
        input.name = name;
        input.location = {};
        input.location.longitude = parseFloat(longitude);
        input.location.latitude = parseFloat(latitude);

        input.date = {};
        input.date.day = birthDate.getDate();
        input.date.month = birthDate.getMonth();
        input.date.year = birthDate.getFullYear();

        input.time = {};
        input.time.hours24 = birthDate.getHours();
        input.time.minutes = birthDate.getMinutes();
        input.time.offset = birthDate.offset();

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });
        const res = await i.post("/user/birthanalysis/initiate", input, { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    getRectificationAnalysis: async (responses) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });
        const res = await i.post("/user/birthanalysis/analyze", responses, { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    faceDetection: async (image) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        let input = {};
        input.image = image;

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });
        const res = await i.post("/tools/face", input);
        return res.data;
    },

    createCheckoutSession: async (priceId) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });
        const res = await i.post("/billing/user/create-checkout-session-2", { priceId }, { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    createPortalSession: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });
        const res = await i.post("/billing/user/create-customer-portal-session-2", { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    getPriceData: async () => {
        const i = axios.create({
            baseURL: config.apiGateway.URL,
        });
        const res = await i.get("/billing/list-prices-2", { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    getBillingStatus: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const endpoint = "/billing/user/billing-status2";
        const axiosInstance = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });

        const res = await axiosInstance.get(endpoint, { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    getUserPredictionsFree: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });
        const res = await i.get("/user/predictions/free", { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    triggerPretraining: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });
        const res = await i.post("/user/predictions/static/pretrain", { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    getTrainingStatus: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });
        const res = await i.get("/user/predictions/static/pretrain/status", { params: { lang: i18n.languages[0] } });
        return res.data;
    },

    askChatQuestion: async (messages, context, contextData, location) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token }
        });
        const res = await i.post("/user/predictions/chat", {messages, context, contextData, location} ,{ params: { lang: i18n.languages[0] } });
        return res.data;
    },

    getMatchingCharts: async (D1) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.post('/user/chart/match', D1, { params: { lang: i18n.languages[0] } })
        return res.data;
    },

    deleteProfile: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.apiGateway.URL,
            headers: { 'token': token },
        });

        const res = await i.delete('/user/delete', { params: { lang: i18n.languages[0] } })
        return res.data;
    },

    verify: async (captchaToken, userAction) => {
        const i = axios.create({
            baseURL: config.apiGateway.URL,
        });
        const res = await i.get("/user/recaptcha/verify", { params: { captchaToken, userAction } });
        return res.data;
    },

}
export default API
import { socialActions } from '../actions/socialActions';
import { combineReducers } from 'redux';
import produce from 'immer';

const defaultFriendsState = {
    confirmed: [],
    sent: [],
    received: [],
    suggested: [],
    isLoading: false
}

const defaultGmailContactsState = {
    raw: [],
    isLoading: false,
    fetched: false,
}


const friends = (state = defaultFriendsState, action) => {
    switch (action.type) {
        case socialActions.FRIENDS_GET_REQUEST:
            return produce(state, draft => {
                draft.isLoading = true;
            })
        case socialActions.FRIENDS_GET_ERROR:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        case socialActions.FRIENDS_REQUEST_SUCCESS:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        case socialActions.FRIENDS_REQUEST_ERROR:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        case socialActions.FRIENDS_ACCEPT_SUCCESS:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        case socialActions.FRIENDS_ACCEPT_ERROR:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        case socialActions.FRIENDS_GET_SUCCESS:
            return produce(state, draft => {
                draft.isLoading = false;
                let confirmed = action.list.filter(contact => contact.status === 'CONFIRMED')
                if (confirmed === undefined) confirmed = []
                draft.confirmed = confirmed
                let sent = action.list.filter(contact => contact.status === 'SENT')
                if (sent === undefined) sent = []
                draft.sent = sent
                let received = action.list.filter(contact => contact.status === 'RECEIVED')
                if (received === undefined) received = []
                draft.received = received
                let suggested = action.list.filter(contact => contact.status === 'SUGGESTION')
                if (suggested === undefined) suggested = []
                draft.suggested = suggested
            })
        default:
            return state
    }
}


const gmail = (state = defaultGmailContactsState, action) => {
    switch (action.type) {
        case socialActions.CONTACTS_GMAIL_REQUEST:
            return produce(state, draft => {
                draft.isLoading = true;
                draft.fetched = true;
            })
        case socialActions.CONTACTS_GMAIL_ERROR:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        case socialActions.CONTACTS_GMAIL_SUCCESS:
            return produce(state, draft => {
                draft.isLoading = false;
                draft.raw = action.processed;
                draft.raw.forEach((item, i) => {
                    item.show = true
                })
            })
        case socialActions.CONTACTS_GMAIL_TOGGLE_ALL:
            return produce(state, draft => {
                draft.raw.forEach((item, i) => {
                    item.selected = !item.selected
                })
            })
        case socialActions.CONTACTS_GMAIL_TOGGLE_ONE:
            return produce(state, draft => {
                draft.raw[action.index].selected = !draft.raw[action.index].selected
            })
        case socialActions.CONTACTS_GMAIL_SEND_ONE:
            return produce(state, draft => {
                draft.raw[action.index].sent = true;
            })
        case socialActions.CONTACTS_GMAIL_FILTER:
            return produce(state, draft => {
                draft.raw.forEach((item, i) => {
                    if(action.include === undefined || action.include === ''){
                        item.show = true
                    }else{
                        if(item.title.toLowerCase().includes(action.include.toLowerCase())){
                            item.show = true
                        }else{
                            item.show = false
                        }
                    }
                })
            })
        default:
            return state
    }
}


const Combined = combineReducers({
    friends, gmail
})

export default Combined


const UI_SIDEBAR_SETSHOW = 'UI::SIDEBAR::SETSHOW'
const UI_SIDEBAR_SETNARROW = 'UI::SIDEBAR::SETNARROW'

const UI_PERIODS_SETMONTH = 'UI::PERIODS::SETMONTH'
const UI_PERIODS_SETYEAR = 'UI::PERIODS::SETYEAR'
const UI_PERIODS_SETLOCATION = 'UI::PERIODS::SETLOCATION'

const UI_VSDASHA_SETMD = 'UI::VSDASHA::SETMD'
const UI_VSDASHA_SETAD = 'UI::VSDASHA::SETAD'
const UI_VSDASHA_SETPAD = 'UI::VSDASHA::SETPAD'

const UI_CHART_PREDICTIONS_ADVANCED = 'UI::CHARTPREDICTIONS::ADVANCED'

const SET_CONSENT_CHECKBOX_STATE = 'CONSENT::CHECKBOX::STATE::SET';
const RESET_CONSENT_CHECKBOX_STATE = 'CONSENT::CHECKBOX::STATE::RESET';


const setSidebarShow = (sbt) => {
    return dispatch => {
        dispatch(success(sbt))
    }
    function success(sb) { return { type: UI_SIDEBAR_SETSHOW, sb } }
}

const setSidebarNarrow = (sbt) => {
    return dispatch => {
        dispatch(success(sbt))
    }
    function success(sb) { return { type: UI_SIDEBAR_SETNARROW, sb } }
}

const setMonth = (m) => {
    return dispatch => {
        dispatch(success(m))
    }
    function success(month) { return { type: UI_PERIODS_SETMONTH, month } }
}

const setYear = (y) => {
    return dispatch => {
        dispatch(success(y))
    }
    function success(year) { return { type: UI_PERIODS_SETYEAR, year } }
}

const setLocation = (l) => {
    return dispatch => {
        dispatch(success(l))
    }
    function success(location) { return { type: UI_PERIODS_SETLOCATION, location } }
}

const setSelectedMd = (d) => {
    return dispatch => {
        dispatch(success(d))
    }
    function success(md) { return { type: UI_VSDASHA_SETMD, md } }
}

const setSelectedAd = (d) => {
    return dispatch => {
        dispatch(success(d))
    }
    function success(ad) { return { type: UI_VSDASHA_SETAD, ad } }
}

const setSelectedPad = (d) => {
    return dispatch => {
        dispatch(success(d))
    }
    function success(pad) { return { type: UI_VSDASHA_SETPAD, pad } }
}

const setChartPredictionsAdvanced = (mode) => {
    return dispatch => {
        dispatch(success(mode))
    }
    function success(advanced) { return { type: UI_CHART_PREDICTIONS_ADVANCED, advanced } }
}

const setCheckboxState = (checkboxName, isChecked) => {
    return dispatch => {
        dispatch(success(checkboxName, isChecked))
    };
    function success(checkboxName, isChecked ) { return { type: SET_CONSENT_CHECKBOX_STATE, checkboxName, isChecked  } }
};

const resetCheckboxState = () => {
    return dispatch => {
        dispatch(success())
    };
    function success( ) { return { type: RESET_CONSENT_CHECKBOX_STATE } }
};

export const uiActions = {
    UI_SIDEBAR_SETSHOW,
    UI_SIDEBAR_SETNARROW,

    UI_PERIODS_SETMONTH,
    UI_PERIODS_SETYEAR,
    UI_PERIODS_SETLOCATION,

    UI_VSDASHA_SETMD,
    UI_VSDASHA_SETAD,
    UI_VSDASHA_SETPAD,

    UI_CHART_PREDICTIONS_ADVANCED,
    
    SET_CONSENT_CHECKBOX_STATE,
    RESET_CONSENT_CHECKBOX_STATE,


    setSidebarShow,
    setSidebarNarrow,

    setMonth,
    setYear,
    setLocation,

    setSelectedMd,
    setSelectedAd,
    setSelectedPad,
    setChartPredictionsAdvanced,
    setCheckboxState,
    resetCheckboxState
};
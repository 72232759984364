import API from "../../utility/API";
import { billingActions } from "./billingActions";

const TRANSIT_CHART_REQUEST = 'TRANSIT::DATA::REQUEST'
const TRANSIT_CHART_CLEAR = 'TRANSIT::DATA::CLEAR'
const TRANSIT_CHART_SUCCESS = 'TRANSIT::DATA::SUCCESS'
const TRANSIT_CHART_ERROR = 'TRANSIT::DATA::ERROR'

const BIRTH_CHART_REQUEST = 'BIRTH::DATA::REQUEST'
const BIRTH_CHART_CLEAR = 'BIRTH::DATA::CLEAR'
const BIRTH_CHART_SUCCESS = 'BIRTH::DATA::SUCCESS'
const BIRTH_CHART_ERROR = 'BIRTH::DATA::ERROR'

const MATCH_CHART_REQUEST = 'MATCH::DATA::REQUEST'
const MATCH_CHART_SUCCESS = 'MATCH::DATA::SUCCESS'
const MATCH_CHART_ERROR = 'MATCH::DATA::ERROR'

const CHART_INFO_REQUEST = 'CHART::INFO::REQUEST'
const CHART_INFO_SUCCESS = 'CHART::INFO::SUCCESS'
const CHART_INFO_ERROR = 'CHART::INFO::ERROR'

const PERIOD_ANALYSIS_REQUEST = 'PERIOD::ANALYSIS::REQUEST'
const PERIOD_ANALYSIS_SUCCESS = 'PERIOD::ANALYSIS::SUCCESS'
const PERIOD_ANALYSIS_ERROR = 'PERIOD::ANALYSIS::ERROR'

const PREDICTIONS_FREE_REQUEST = 'PREDICTIONS::FREE::REQUEST'
const PREDICTIONS_FREE_SUCCESS = 'PREDICTIONS::FREE:SUCCESS'
const PREDICTIONS_FREE_ERROR = 'PREDICTIONS::FREE:ERROR'

const PREDICTIONS_TRAINING_REQUEST = 'PREDICTIONS::TRAINING::REQUEST'
const PREDICTIONS_TRAINING_SUCCESS = 'PREDICTIONS::TRAINING::SUCCESS'
const PREDICTIONS_TRAINING_ERROR = 'PREDICTIONS::TRAINING:ERROR'

const TRAINING_STATUS_REQUEST = 'TRAINING::STATUS::REQUEST'
const TRAINING_STATUS_SUCCESS = 'TRAINING::STATUS::SUCCESS'
const TRAINING_STATUS_ERROR = 'TRAINING::STATUS:ERROR'

const CHAT_MESSAGE_REQUEST = 'CHAT::MESSAGE::REQUEST'
const CHAT_MESSAGE_RESPONSE = 'CHAT::MESSAGE::RESPONSE'
const CHAT_MESSAGE_ERROR = 'CHAT::MESSAGE::ERROR'

const CHAT_HISTORY_CLEAR = 'CHAT::HISTORY::CLEAR'
const UPDATE_CHAT_QUESTION = 'UPDATE::CHAT::QUESTION';


const clear_transit_chart = () => {
    return { type: TRANSIT_CHART_CLEAR }
}

const calculate_transit_chart = (latitude, longitude, birthLatitude, birthLongitude, birthDate, birthName, address) => {
    return dispatch => {
        dispatch(request());
        API.getTransit(latitude, longitude, birthLatitude, birthLongitude, birthDate, birthName, address).then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    }

    function request() { return { type: TRANSIT_CHART_REQUEST } }
    function success(native) { return { type: TRANSIT_CHART_SUCCESS, native } }
    function failure(error) { return { type: TRANSIT_CHART_ERROR, error } }
}

const clear_birth_chart = () => {
    return { type: BIRTH_CHART_CLEAR }
}

const calculate_birth_chart = (latitude, longitude, birthDate, name) => {
    return dispatch => {
        dispatch(request())
        API.getBirthChart(latitude, longitude, birthDate, name).then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: BIRTH_CHART_REQUEST } }
    function success(native) { return { type: BIRTH_CHART_SUCCESS, native } }
    function failure(error) { return { type: BIRTH_CHART_ERROR, error } }
}

const getChartInfo = (id) => {
    return dispatch => {
        dispatch(request())
        API.getChartInfo(id).then(data => {
            dispatch(success(data.birthinfo))
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: CHART_INFO_REQUEST } }
    function success(info) { return { type: CHART_INFO_SUCCESS, info } }
    function failure(error) { return { type: CHART_INFO_ERROR, error } }
}

const getMatchingCharts = (D1) => {
    return dispatch => {
        dispatch(request())
        API.getMatchingCharts(D1).then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: MATCH_CHART_REQUEST } }
    function success(match) { return { type: MATCH_CHART_SUCCESS, match } }
    function failure(error) { return { type: MATCH_CHART_ERROR, error } }
}

const getChartPredictionsFree = () => {
    return dispatch => {
        dispatch(request())
        API.getUserPredictionsFree().then(data => {
            dispatch(success(data.file))
        }).catch(err => {
            dispatch(failure(err));
        })
    };
    function request() { return { type: PREDICTIONS_FREE_REQUEST } }
    function success(file) { return { type: PREDICTIONS_FREE_SUCCESS, file } }
    function failure(error) { return { type: PREDICTIONS_FREE_ERROR, error } }
}

const get_period_analysis = (current_latitude, current_longitude, birth_latitude, birth_longitude, birthDate, name, startDate, endDate) => {
    return dispatch => {
        dispatch(request())
        API.getPeriodAnalysis(current_latitude, current_longitude, birth_latitude, birth_longitude, birthDate, name, startDate, endDate).then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: PERIOD_ANALYSIS_REQUEST } }
    function success(native) { return { type: PERIOD_ANALYSIS_SUCCESS, native } }
    function failure(error) { return { type: PERIOD_ANALYSIS_ERROR, error } }
}

const start_chart_training = () => {
    return dispatch => {
        dispatch(request())
        API.triggerPretraining().then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: PREDICTIONS_TRAINING_REQUEST } }
    function success() { return { type: PREDICTIONS_TRAINING_SUCCESS } }
    function failure(error) { return { type: PREDICTIONS_TRAINING_ERROR, error } }
}

const getTrainingStatus = () => {
    return dispatch => {
        dispatch(request())
        API.getTrainingStatus().then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: TRAINING_STATUS_REQUEST } }
    function success(payload) { return { type: TRAINING_STATUS_SUCCESS, payload } }
    function failure(error) { return { type: TRAINING_STATUS_ERROR, error } }
}

export const askChatQuestion = (question, context, contextData) => (dispatch, getState) => {
    const currentMessages = getState().astro.chat.messages;
    const newQuestionText = question;
    const location = getState().location.current;

    const newQuestion = {
        role: 'user',
        content: newQuestionText,
    };

    const updatedMessages = [...(currentMessages || []), newQuestion];
    dispatch(request(updatedMessages));

    API.askChatQuestion(updatedMessages, context, contextData, location).then(data => {
        dispatch(success(data));
        dispatch(billingActions.updateQuota(data.quota));
    }).catch(err => {
        dispatch(failure(err));
    });

    function request(messages) { return { type: CHAT_MESSAGE_REQUEST , messages}; }
    function success(data) { return { type: CHAT_MESSAGE_RESPONSE, data }; }
    function failure(error) { return { type: CHAT_MESSAGE_ERROR, error }; }
};


const clearChatHistory = () => {
    return dispatch => {
        dispatch(request())
    };

    function request() { return { type: CHAT_HISTORY_CLEAR } }
}

const updateQuestion = (q) => {
    return dispatch => {
        dispatch(request(q))
    };

    function request(question) { return { type: UPDATE_CHAT_QUESTION , question} }
}

export const chartActions = {
    calculate_transit_chart,
    calculate_birth_chart,
    clear_birth_chart,
    clear_transit_chart,

    TRANSIT_CHART_REQUEST,
    TRANSIT_CHART_CLEAR,
    TRANSIT_CHART_SUCCESS,
    TRANSIT_CHART_ERROR,

    BIRTH_CHART_REQUEST,
    BIRTH_CHART_CLEAR,
    BIRTH_CHART_SUCCESS,
    BIRTH_CHART_ERROR,

    CHART_INFO_REQUEST,
    CHART_INFO_SUCCESS,
    CHART_INFO_ERROR,

    PERIOD_ANALYSIS_REQUEST,
    PERIOD_ANALYSIS_SUCCESS,
    PERIOD_ANALYSIS_ERROR,

    PREDICTIONS_FREE_REQUEST,
    PREDICTIONS_FREE_SUCCESS,
    PREDICTIONS_FREE_ERROR,

    MATCH_CHART_REQUEST,
    MATCH_CHART_SUCCESS,
    MATCH_CHART_ERROR,

    PREDICTIONS_TRAINING_REQUEST,
    PREDICTIONS_TRAINING_SUCCESS,
    PREDICTIONS_TRAINING_ERROR,

    TRAINING_STATUS_REQUEST,
    TRAINING_STATUS_SUCCESS,
    TRAINING_STATUS_ERROR,

    CHAT_MESSAGE_ERROR,
    CHAT_MESSAGE_REQUEST,
    CHAT_MESSAGE_RESPONSE,

    CHAT_HISTORY_CLEAR,
    UPDATE_CHAT_QUESTION,

    getMatchingCharts,
    getChartInfo,
    get_period_analysis,
    getChartPredictionsFree,
    start_chart_training,
    getTrainingStatus,
    askChatQuestion,
    clearChatHistory,
    updateQuestion
};


import { adminActions } from '../actions/adminActions';
import { combineReducers } from 'redux';
import stringify from 'json-stringify-deterministic';
import sortKeysRecursive from 'sort-keys-recursive';
import produce from 'immer';

const emptyBELang = {
    activeTab: 'Nakshatras',
    Nakshatras: {
        activeTab : 'NAK',
        NAK: {
            processing: false
        }
    },
    Planets: {
        activeTab : 'PLA',
        PLA: {
            processing: false
        }
    },
    Signs: {
        activeTab : 'SIG',
        SIG: {
            processing: false
        }
    },
    Lagnas: {
        activeTab : 'ASC',
        ASC: {
            processing: false
        }
    },
    Lords: {
        activeTab : 'LOR1',
        LOR1: {
            processing: false
        },
        LOR2: {
            processing: false
        },
        LOR3: {
            processing: false
        },
        LOR4: {
            processing: false
        },
        LOR5: {
            processing: false
        },
        LOR6: {
            processing: false
        },
        LOR7: {
            processing: false
        },
        LOR8: {
            processing: false
        },
        LOR9: {
            processing: false
        },
        LOR10: {
            processing: false
        },
        LOR11: {
            processing: false
        },
        LOR12: {
            processing: false
        }
    }
}

const emptyFELang = {
    activeTab: '',
    processing: false,
}

const defaultBEState = {
    processing: false,
    activeLang: 'en',
    languages: {
        en: emptyBELang,
        hi: emptyBELang,
        fr: emptyBELang,
        es: emptyBELang,
        ru: emptyBELang,
        de: emptyBELang,
        zh: emptyBELang,
        ja: emptyBELang
    }
}

const defaultFEState = {
    processing: false,
    activeLang: 'en',
    languages: {
        en: emptyFELang,
        hi: emptyFELang,
        fr: emptyFELang,
        es: emptyFELang,
        ru: emptyFELang,
        de: emptyFELang,
        zh: emptyFELang,
        ja: emptyFELang
    }
}

const defaultRootState = {
    processing: true,
    supportedLanguages: [],
    namespaces: {
        en: {
            content: []
        },
        hi: {
            content: []
        }
    }
}

const root = (state = defaultRootState, action) => {
    switch (action.type) {
        case adminActions.ROOT_GETLANG_REQUEST:
            return produce(state, draft => {
                draft.processing = true;
            })
        case adminActions.ROOT_GETLANG_ERROR:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_GETLANG_SUCCESS:
            return produce(state, draft => {
                draft.processing = false;
                draft.supportedLanguages = action.langs;
            })
        case adminActions.ROOT_ADDLANG_REQUEST:
            return produce(state, draft => {
                draft.processing = true;
            })
        case adminActions.ROOT_ADDLANG_ERROR:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_ADDLANG_SUCCESS:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_DELLANG_REQUEST:
            return produce(state, draft => {
                draft.processing = true;
            })
        case adminActions.ROOT_DELLANG_ERROR:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_DELLANG_SUCCESS:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_ADDTXNS_REQUEST:
            return produce(state, draft => {
                draft.processing = true;
            })
        case adminActions.ROOT_ADDTXNS_ERROR:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_ADDTXNS_SUCCESS:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_DELTXNS_REQUEST:
            return produce(state, draft => {
                draft.processing = true;
            })
        case adminActions.ROOT_DELTXNS_ERROR:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_DELTXNS_SUCCESS:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_GETTXNS_REQUEST:
            return produce(state, draft => {
                draft.processing = true;
            })
        case adminActions.ROOT_GETTXNS_ERROR:
            return produce(state, draft => {
                draft.processing = false;
            })
        case adminActions.ROOT_GETTXNS_SUCCESS:
            return produce(state, draft => {
                draft.processing = false;
                let ns = []
                for (let i = 0; i < action.content.length; i++) {
                    const item = action.content[i].contentId.split("-")[3]
                    ns.push(item)
                }
                ns.sort()
                draft.namespaces[action.lang].content = ns
            })
        default:
            return state
    }
}

const be = (state = defaultBEState, action) => {
    switch (action.type) {
        case adminActions.CMS_BEGETCONTENT_REQUEST:
            return produce(state, draft => {
                const lang = draft.activeLang
                const tab = draft.languages[lang].activeTab
                const subtab = draft.languages[lang][tab].activeTab
                draft.processing = true
                draft.languages[lang][tab][subtab].processing = true;
            })
        case adminActions.CMS_BEGETCONTENT_ERROR:
            return produce(state, draft => {
                const lang = draft.activeLang
                const tab = draft.languages[lang].activeTab
                draft.processing = false
                draft.languages[lang][tab].processing = false;
            })
        case adminActions.CMS_BEGETCONTENT_SUCCESS:
            return produce(state, draft => {
                const lang = draft.activeLang
                const tab = draft.languages[lang].activeTab
                const subtab = draft.languages[lang][tab].activeTab
                draft.processing = false
                draft.languages[lang][tab][subtab].processing = false;
                if (draft.languages[lang][tab][subtab].content === undefined) {
                    draft.languages[lang][tab][subtab].content = {}
                }
                const content = action.content
                content.forEach((entry) => {
                    const updated = Object.keys(entry.updated).length === 0 ? new Date(2021, 0, 1).toISOString() : entry.updated
                    const parts = entry.contentId.split("-")
                    switch (true) {
                        case (subtab === 'NAK'):
                            if (draft.languages[lang][tab][subtab].content[parts[3]] === undefined) {
                                draft.languages[lang][tab][subtab].content[parts[3]] = {}
                            }
                            if (draft.languages[lang][tab][subtab].content[parts[3]][parts[4]] === undefined) {
                                draft.languages[lang][tab][subtab].content[parts[3]][parts[4]] = {}
                            }
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].nakshatra = parts[3]
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].pada = parts[4]
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].contentId = entry.contentId
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = stringify(sortKeysRecursive(entry.content), {space:  '\t'})
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updatedBy = entry.updateBy
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updated = updated
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = false
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = false
                            break;
                        case (subtab === 'PLA'):
                            if (draft.languages[lang][tab][subtab].content[parts[3]] === undefined) {
                                draft.languages[lang][tab][subtab].content[parts[3]] = {}
                            }
                            if (draft.languages[lang][tab][subtab].content[parts[3]][parts[4]] === undefined) {
                                draft.languages[lang][tab][subtab].content[parts[3]][parts[4]] = {}
                            }
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].planet = parts[3]
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].inHouse = parts[4]
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].contentId = entry.contentId
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = stringify(sortKeysRecursive(entry.content), {space:  '\t'})
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updatedBy = entry.updateBy
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updated = updated
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = false
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = false
                            break;
                        case (subtab === 'SIG'):
                            if (draft.languages[lang][tab][subtab].content[parts[3]] === undefined) {
                                draft.languages[lang][tab][subtab].content[parts[3]] = {}
                            }
                            if (draft.languages[lang][tab][subtab].content[parts[3]][parts[4]] === undefined) {
                                draft.languages[lang][tab][subtab].content[parts[3]][parts[4]] = {}
                            }
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].planet = parts[3]
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].inSign = parts[4]
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].contentId = entry.contentId
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = stringify(sortKeysRecursive(entry.content), {space:  '\t'})
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updatedBy = entry.updateBy
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updated = updated
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = false
                            draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = false
                            break;
                        case (subtab === 'ASC'): {
                            const sign = parts[3]
                            if (draft.languages[lang][tab][subtab].content[sign] === undefined) {
                                draft.languages[lang][tab][subtab].content[sign] = {}
                            }
                            draft.languages[lang][tab][subtab].content[sign].sign = sign
                            draft.languages[lang][tab][subtab].content[sign].contentId = entry.contentId
                            draft.languages[lang][tab][subtab].content[sign].content = stringify(sortKeysRecursive(entry.content), {space:  '\t'})
                            draft.languages[lang][tab][subtab].content[sign].updatedBy = entry.updateBy
                            draft.languages[lang][tab][subtab].content[sign].updated = updated
                            draft.languages[lang][tab][subtab].content[sign].isDirty = false
                            draft.languages[lang][tab][subtab].content[sign].editable = false
                            break;
                        }
                        case (subtab.startsWith('LOR')):{
                            const lordOf = parts[2].replace('LOR', '')
                            const placedIn = parts[3]
                            const asc = parts[4]
                            if (draft.languages[lang][tab][subtab].content[placedIn] === undefined) {
                                draft.languages[lang][tab][subtab].content[placedIn] = {}
                            }
                            if (draft.languages[lang][tab][subtab].content[placedIn][asc] === undefined) {
                                draft.languages[lang][tab][subtab].content[placedIn][asc] = {}
                            }
                            draft.languages[lang][tab][subtab].content[placedIn][asc].lordOf = lordOf
                            draft.languages[lang][tab][subtab].content[placedIn][asc].placedIn = placedIn
                            draft.languages[lang][tab][subtab].content[placedIn][asc].lagna = asc
                            draft.languages[lang][tab][subtab].content[placedIn][asc].contentId = entry.contentId
                            draft.languages[lang][tab][subtab].content[placedIn][asc].content = stringify(sortKeysRecursive(entry.content), {space:  '\t'})
                            draft.languages[lang][tab][subtab].content[placedIn][asc].updatedBy = entry.updateBy
                            draft.languages[lang][tab][subtab].content[placedIn][asc].updated = updated
                            draft.languages[lang][tab][subtab].content[placedIn][asc].isDirty = false
                            draft.languages[lang][tab][subtab].content[placedIn][asc].editable = false
                            break;
                        }
                        default:
                    }
                })
            })
        case adminActions.CMS_SAVEBEITEM_REQUEST:
            return produce(state, draft => {
                draft.processing = true
            })
        case adminActions.CMS_SAVEBEITEM_ERROR:
            return produce(state, draft => {
                draft.processing = false
            })
        case adminActions.CMS_SAVEBEITEM_SUCCESS:
            return produce(state, draft => {
                draft.processing = false
                const id = action.saved.contentId
                const parts = id.split("-")
                const lang = parts[1]
                const tab = draft.languages[lang].activeTab
                const subtab = parts[2]
                switch (true) {
                    case (subtab === 'NAK'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = false
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = false
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updatedBy = action.saved.updateBy
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updated = action.saved.updated
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].audio = undefined
                        break;
                    case (subtab === 'PLA'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = false
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = false
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updatedBy = action.saved.updateBy
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updated = action.saved.updated
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].audio = undefined
                        break;
                    case (subtab === 'SIG'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = false
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = false
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updatedBy = action.saved.updateBy
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].updated = action.saved.updated
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].audio = undefined
                        break;
                    case (subtab === 'ASC'):{
                        const sign = parts[3]
                        draft.languages[lang][tab][subtab].content[sign].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[sign].isDirty = false
                        draft.languages[lang][tab][subtab].content[sign].editable = false
                        draft.languages[lang][tab][subtab].content[sign].updatedBy = action.saved.updateBy
                        draft.languages[lang][tab][subtab].content[sign].updated = action.saved.updated
                        draft.languages[lang][tab][subtab].content[sign].audio = undefined
                        break;
                    }
                    case (subtab.startsWith('LOR')):{
                        const placedIn = parts[3]
                        const asc = parts[4]
                        draft.languages[lang][tab][subtab].content[placedIn][asc].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[placedIn][asc].isDirty = false
                        draft.languages[lang][tab][subtab].content[placedIn][asc].editable = false
                        draft.languages[lang][tab][subtab].content[placedIn][asc].updatedBy = action.saved.updateBy
                        draft.languages[lang][tab][subtab].content[placedIn][asc].updated = action.saved.updated
                        draft.languages[lang][tab][subtab].content[placedIn][asc].audio = undefined
                        break;
                    }
                    default:
                }
            })
        case adminActions.CMS_BEGETCONTENTITEM_REQUEST:
            return produce(state, draft => {
                draft.processing = true
            })
        case adminActions.CMS_BEGETCONTENTITEM_ERROR:
            return produce(state, draft => {
                draft.processing = false
            })
        case adminActions.CMS_BEGETCONTENTITEM_SUCCESS:
            return produce(state, draft => {
                draft.processing = false
                const id = action.saved.contentId
                const parts = id.split("-")
                const lang = parts[1]
                const tab = draft.languages[lang].activeTab
                const subtab = parts[2]
                switch (true) {
                    case (subtab === 'NAK'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = false
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = false
                        break;
                    case (subtab === 'PLA'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = false
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = false
                        break;
                    case (subtab === 'SIG'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = false
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = false
                        break;
                    case (subtab === 'ASC'):{
                        const sign = parts[3]
                        draft.languages[lang][tab][subtab].content[sign].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[sign].isDirty = false
                        draft.languages[lang][tab][subtab].content[sign].editable = false
                        break;
                    }
                    case (subtab.startsWith('LOR')):{
                        const placedIn = parts[3]
                        const asc = parts[4]
                        draft.languages[lang][tab][subtab].content[placedIn][asc].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                        draft.languages[lang][tab][subtab].content[placedIn][asc].isDirty = false
                        draft.languages[lang][tab][subtab].content[placedIn][asc].editable = false
                        break;
                    }
                    default:
                }
            })
        case adminActions.CMS_BEGETCONTENTAUDIO_REQUEST:
            return produce(state, draft => {
                draft.processing = true
            })
        case adminActions.CMS_BEGETCONTENTAUDIO_ERROR:
            return produce(state, draft => {
                draft.processing = false
            })
        case adminActions.CMS_BEGETCONTENTAUDIO_SUCCESS:
            return produce(state, draft => {
                draft.processing = false
                const id = action.id
                const parts = id.split("-")
                const lang = parts[1]
                const tab = draft.languages[lang].activeTab
                const subtab = parts[2]
                switch (true) {
                    case (subtab === 'NAK'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].audio = action.mp3
                        break;
                    case (subtab === 'PLA'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].audio = action.mp3
                        break;
                    case (subtab === 'SIG'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].audio = action.mp3
                        break;
                    case (subtab === 'ASC'):{
                        const sign = parts[3]
                        draft.languages[lang][tab][subtab].content[sign].audio = action.mp3
                        break;
                    }
                    case (subtab.startsWith('LOR')):{
                        const placedIn = parts[3]
                        const asc = parts[4]
                        draft.languages[lang][tab][subtab].content[placedIn][asc].audio = action.mp3
                        break;
                    }
                    default:
                }
            })
        case adminActions.CMS_BE_SETLANG:
            return produce(state, draft => {
                draft.activeLang = action.lang
            })
        case adminActions.CMS_BE_SETTAB:
            return produce(state, draft => {
                const lang = draft.activeLang
                draft.languages[lang].activeTab = action.tab
                if(action.sub === undefined){
                    switch(action.tab){
                        case ('Nakshatras'):
                            draft.languages[lang][action.tab].activeTab = 'NAK'
                            break
                        case ('Planets'):
                            draft.languages[lang][action.tab].activeTab = 'PLA'
                            break
                        case ('Signs'):
                            draft.languages[lang][action.tab].activeTab = 'SIG'
                            break
                        case ('Lagnas'):
                            draft.languages[lang][action.tab].activeTab = 'ASC'
                            break
                        case ('Lords'):
                            draft.languages[lang][action.tab].activeTab = 'LOR1'
                            break
                        default:
                    }
                }else{
                    draft.languages[lang][action.tab].activeTab = action.sub
                }
            })
        case adminActions.CMS_BE_SETITEM:
            return produce(state, draft => {
                const id = action.id
                const parts = id.split("-")
                const lang = parts[1]
                const tab = draft.languages[lang].activeTab
                const subtab = parts[2]
                switch (true) {
                    case (subtab === 'NAK'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = action.content
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = true
                        break;
                    case (subtab === 'PLA'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = action.content
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = true
                        break;
                    case (subtab === 'SIG'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].content = action.content
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].isDirty = true
                        break;
                    case (subtab === 'ASC'):{
                        const sign = parts[3]
                        draft.languages[lang][tab][subtab].content[sign].content = action.content
                        draft.languages[lang][tab][subtab].content[sign].isDirty = true
                        break;
                    }
                    case (subtab.startsWith('LOR')):{
                        const placedIn = parts[3]
                        const asc = parts[4]
                        draft.languages[lang][tab][subtab].content[placedIn][asc].content = action.content
                        draft.languages[lang][tab][subtab].content[placedIn][asc].isDirty = true
                        break;
                    }
                    default:
                }
            })
        case adminActions.CMS_BE_SETITEMEDITABLE:
            return produce(state, draft => {
                const id = action.id
                const parts = id.split("-")
                const lang = parts[1]
                const tab = draft.languages[lang].activeTab
                const subtab = parts[2]
                switch (true) {
                    case (subtab === 'NAK'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = !draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable
                        break;
                    case (subtab === 'PLA'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = !draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable
                        break;
                    case (subtab === 'SIG'):
                        draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable = !draft.languages[lang][tab][subtab].content[parts[3]][parts[4]].editable
                        break;
                    case (subtab === 'ASC'):{
                        const sign = parts[3]
                        draft.languages[lang][tab][subtab].content[sign].editable = !draft.languages[lang][tab][subtab].content[sign].editable
                        break;
                    }
                    case (subtab.startsWith('LOR')):{
                        const placedIn = parts[3]
                        const asc = parts[4]
                        draft.languages[lang][tab][subtab].content[placedIn][asc].editable = !draft.languages[lang][tab][subtab].content[placedIn][asc].editable
                        break;
                    }
                    default:
                }
            })
        default:
            return state
    }
}

const fe = (state = defaultFEState, action) => {
    switch (action.type) {
        case adminActions.CMS_FEGETCONTENT_REQUEST:
            return produce(state, draft => {
                draft.processing = true
            })
        case adminActions.CMS_FEGETCONTENT_ERROR:
            return produce(state, draft => {
                draft.processing = false
            })
        case adminActions.CMS_FEGETCONTENT_SUCCESS:
            return produce(state, draft => {
                const lang = action.category.split("-")[1]
                draft.processing = false
                draft.languages[lang].processing = false;
                if (draft.languages[lang].content === undefined) {
                    draft.languages[lang].content = {}
                }
                const content = action.content
                content.forEach((entry) => {
                    const ns = entry.contentId.split("-")[3]
                    const c = {
                        contentId: entry.contentId,
                        content: stringify(sortKeysRecursive(entry.content), {space:  '\t'}),
                        namespace: ns,
                        isDirty: false,
                        editable: false
                    }
                    draft.languages[lang].content[ns] = c
                })
                draft.languages[lang].content = sortKeysRecursive(draft.languages[lang].content)
                if (draft.languages[lang].content && Object.keys(draft.languages[lang].content).length !== 0) {
                    const first = Object.keys(draft.languages[lang].content)[0]
                    draft.languages[lang].activeTab = draft.languages[lang].content[first].namespace
                }
            })
        case adminActions.CMS_SAVEFEITEM_REQUEST:
            return produce(state, draft => {
                draft.processing = true
            })
        case adminActions.CMS_SAVEFEITEM_ERROR:
            return produce(state, draft => {
                draft.processing = false
            })
        case adminActions.CMS_SAVEFEITEM_SUCCESS:
            return produce(state, draft => {
                draft.processing = false
                const id = action.saved.contentId
                const parts = id.split("-")
                const lang = parts[1]
                const tab = parts[3]
                draft.languages[lang].content[tab].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                draft.languages[lang].content[tab].isDirty = false
                draft.languages[lang].content[tab].editable = false
            })
        case adminActions.CMS_FEGETCONTENTITEM_REQUEST:
            return produce(state, draft => {
                draft.processing = true
            })
        case adminActions.CMS_FEGETCONTENTITEM_ERROR:
            return produce(state, draft => {
                draft.processing = false
            })
        case adminActions.CMS_FEGETCONTENTITEM_SUCCESS:
            return produce(state, draft => {
                draft.processing = false
                const id = action.saved.contentId
                const parts = id.split("-")
                const lang = parts[1]
                const tab = parts[3]
                draft.languages[lang].content[tab].content = stringify(sortKeysRecursive(action.saved.content), {space:  '\t'})
                draft.languages[lang].content[tab].isDirty = false
                draft.languages[lang].content[tab].editable = false
            })
        case adminActions.CMS_FE_SETLANG:
            return produce(state, draft => {
                draft.activeLang = action.lang
            })
        case adminActions.CMS_FE_SETTAB:
            return produce(state, draft => {
                const lang = draft.activeLang
                draft.languages[lang].activeTab = action.tab
            })
        case adminActions.CMS_FE_SETITEM:
            return produce(state, draft => {
                const id = action.id
                const parts = id.split("-")
                const lang = parts[1]
                const tab = parts[3]
                draft.languages[lang].content[tab].content = action.content
                draft.languages[lang].content[tab].isDirty = true
            })
        case adminActions.CMS_FE_SETITEMEDITABLE:
            return produce(state, draft => {
                const id = action.id
                const parts = id.split("-")
                const lang = parts[1]
                const tab = parts[3]
                draft.languages[lang].content[tab].editable = !draft.languages[lang].content[tab].editable
            })
        default:
            return state
    }
}


const Combined = combineReducers({
    root, be, fe
})

export default Combined

import config from "../config/Config";
import { Auth } from "aws-amplify";
import axios from 'axios';

const Admin = {

    getContentByCategory: async (astrologer, category) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.get(`/cms/predictions/astrologer/${astrologer}/category/${category}`)
        return res.data;
    },

    delContentByCategory: async (astrologer, category) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.delete(`/admin/languages/astrologer/${astrologer}/category/${category}`)
        return res.data;
    },

    getContentById: async (astrologer, id) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        
        const res = await i.get(`/cms/predictions/astrologer/${astrologer}/id/${id}`)
        return res.data;
    },

    saveContentById: async (astrologer, language, id, content) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        
        const res = await i.post(`/cms/predictions/astrologer/${astrologer}/language/${language}`, { id, content })
        return res.data;
    },

    getSupportedLanguages: async (astrologer) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        
        const res = await i.get(`/admin/languages/astrologer/${astrologer}`)
        return res.data;
    },

    addSupportedLanguage: async (astrologer, language) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        
        const res = await i.post(`/admin/languages/astrologer/${astrologer}/language/${language}`)
        return res.data;
    },

    delSupportedLanguage: async (astrologer, language) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        
        const res = await i.delete(`/admin/languages/astrologer/${astrologer}/language/${language}`)
        return res.data;
    },

    addTranslationNS: async (namespace) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/ux/translations/${namespace}`)
        return res.data;
    },

    delTranslationNS: async (namespace) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.delete(`/admin/ux/translations/${namespace}`)
        return res.data;
    },

    addCategory: async (astrologer, category) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/languages/astrologer/${astrologer}/category/${category}`)
        return res.data;
    },

    listen: async (astrologer, lang, content) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/astrologer/${astrologer}/language/${lang}/listen`, { content })
        return res.data;
    },

    listResources: async (id) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.get(`/cms/resource/category/${id}`)
        return res.data;
    },

    deleteResource: async (id) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.delete(`/admin/cms/resource/id/${id}`)
        return res.data;
    },

    getResource: async (id) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.get(`/admin/cms/resource/id/${id}`)
        return res.data;
    },

    createOrUpdateResource: async (info) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.put(`/admin/cms/resource`, info )
        return res.data;
    },

    generateResourcePage: async (id) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/cms/resource/create/${id}` )
        return res.data;
    },

    clearCache: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        await i.delete(`/admin/cms/cache` )
    },

    scrapeAstroDatabankLinks: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.get(`/admin/cms/scrape/links` )
        return res;
    },

    scrapeAstroDatabankContent: async (id) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.get(`/admin/cms/scrape/content/id/${id}` )
        return res.data;
    },

    generateCelebritiesByCategory: async (cat) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/cms/resource/create/celebrities/category/${cat}` )
        return res.data;
    },

    generateCelebrityFilters: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/cms/resource/create/celebrities/filter` )
        return res.data;
    },

    generateEventsByCategory: async (cat) => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/cms/resource/create/events/category/${cat}` )
        return res.data;
    },

    generateEventFilters: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/cms/resource/create/events/filter` )
        return res.data;
    },

    reconcile: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/reconcile` )
        return res.data;
    },

    postreconcile: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/postreconcile` )
        return res.data;
    },

    triggerRefresh: async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        const i = axios.create({
            baseURL: config.cms.URL,
            headers: { 'token': token },
        });
        const res = await i.post(`/admin/trigger-refresh` )
        return res.data;
    },
  
}
export default Admin
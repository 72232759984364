import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import createRootReducer from './reducers/rootReducer';


//const logger = createLogger({ collapsed: false })


const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production',
  collapsed: true
});

const initialState = {}

export default function configureStore() {
  const store = createStore(
    createRootReducer(), 
    initialState,
    applyMiddleware(thunk, logger)
  );

  return store
}
import { calibrationActions } from '../actions/calibrationActions';
import { combineReducers } from 'redux';
import produce from 'immer';

const defaultEventState = {
    processing: false,
    saving: false,
    deleting: false,
    list: []
}

const lifeEvents = (state = defaultEventState, action) => {
    switch (action.type) {
        case calibrationActions.GET_LIFE_EVENTS_REQUEST:
            return produce(state, draft => {
                draft.processing = true;
            })
        case calibrationActions.GET_LIFE_EVENTS_SUCCESS:
            return produce(state, draft => {
                draft.list = action.info;
                draft.processing = false;
            })
        case calibrationActions.GET_LIFE_EVENTS_ERROR:
            return produce(state, draft => {
                draft.processing = false;
            })
        case calibrationActions.DELETE_LIFE_EVENTS_REQUEST:
            return produce(state, draft => {
                draft.deleting = true;
                draft.processing = true;
            })
        case calibrationActions.DELETE_LIFE_EVENTS_SUCCESS:
            return produce(state, draft => {
                draft.deleting = false;
            })
        case calibrationActions.DELETE_LIFE_EVENTS_ERROR:
            return produce(state, draft => {
                draft.deleting = false;
            })
        case calibrationActions.ADD_LIFE_EVENTS_REQUEST:
            return produce(state, draft => {
                draft.saving = true;
                draft.processing = true;
            })
        case calibrationActions.ADD_LIFE_EVENTS_SUCCESS:
            return produce(state, draft => {
                draft.list = action.info;
                draft.saving = false;
            })
        case calibrationActions.ADD_LIFE_EVENTS_ERROR:
            return produce(state, draft => {
                draft.saving = false;
            })
        default:
            return state
    }
}

const defaultZodiacSurveyState = {
    processing: false,
    current: undefined,
    confidence: [],
    image: undefined,
    progress: undefined,
    complete: false
}

const zodiacSurvey = (state = defaultZodiacSurveyState, action) => {
    switch (action.type) {
        case calibrationActions.PROCESS_ZODIAC_SURVEY_REQUEST:
            return produce(state, draft => {
                draft.processing = true;
            })
        case calibrationActions.PROCESS_ZODIAC_SURVEY_SUCCESS:
            return produce(state, draft => {
                draft.processing = false;
                draft.confidence = action.info.confidence;
                draft.image = action.info.image;
                draft.progress = action.info.progress;
                draft.current = action.info.next;
                draft.complete = action.info.complete;
                draft.actualImg = action.info.actualImg;
                draft.calibratedImg = action.info.calibratedImg;
                draft.predictedSV = action.info.pSV;
            })
        case calibrationActions.PROCESS_ZODIAC_SURVEY_ERROR:
            return produce(state, draft => {
                draft.processing = false;
            })
        case calibrationActions.RESTART_ZODIAC_SURVEY_REQUEST:
            return produce(state, draft => {
                draft.processing = true;
            })
        case calibrationActions.RESTART_ZODIAC_SURVEY_SUCCESS:
            return produce(state, draft => {
                draft.processing = false;
                draft.confidence = action.info.confidence;
                draft.image = action.info.image;
                draft.progress = action.info.progress;
                draft.current = action.info.next;
                draft.complete = false;
                draft.actualImg = action.info.actualImg;
                draft.calibratedImg = action.info.calibratedImg;
                draft.predictedSV = action.info.pSV;
            })
        case calibrationActions.RESTART_ZODIAC_SURVEY_ERROR:
            return produce(state, draft => {
                draft.processing = false;
            })
        default:
            return state
    }
}

const defaultBiodata = {
    isLoading: false,
    gender: {
        physical: undefined,
        mental: undefined
    },
    profession: undefined,
    education: undefined,
    maritalStatus: undefined,
    children: undefined
}

const biodata = (state = defaultBiodata, action) => {
    switch (action.type) {
        case calibrationActions.BIODATA_FETCH_REQUEST:
            return produce(state, draft => {
                draft.isLoading = true;
            })
        case calibrationActions.BIODATA_FETCH_SUCCESS:
            return produce(state, draft => {
                draft.isLoading = false;
                draft.gender = action.data.gender;
                draft.profession = action.data.profession;
                draft.education = action.data.education;
                draft.maritalStatus = action.data.maritalStatus;
                draft.children = action.data.children;
            } )     
        case calibrationActions.BIODATA_FETCH_ERROR:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        case calibrationActions.BIODATA_SAVE_REQUEST:   
            return produce(state, draft => {
                draft.isLoading = true;
                draft.gender = action.data.gender;
                draft.profession = action.data.profession;
                draft.education = action.data.education;
                draft.maritalStatus = action.data.maritalStatus;
                draft.children = action.data.children;
            })
        case calibrationActions.BIODATA_SAVE_SUCCESS:
            return produce(state, draft => {
                draft.isLoading = false;
            })  
        case calibrationActions.BIODATA_SAVE_ERROR:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        default:
            return state
    }
}

const Combined = combineReducers({
    lifeEvents , zodiacSurvey, biodata
})

export default Combined
import { dashaActions } from '../actions/dashaActions';
import { combineReducers } from 'redux';
import produce from 'immer';

const defaultDashaState = {
  open: {
    md: null,
    ad: null,
    pad: null
  }
}

const selection = (state = defaultDashaState, action) => {
  switch (action.type) {
    case dashaActions.DASHA_SET_MD:
      return produce(state, draft => {
        draft.open.md = action.md;
      })
    case dashaActions.DASHA_SET_AD:
      return produce(state, draft => {
        draft.open.ad = action.ad;
      })
    case dashaActions.DASHA_SET_PAD:
      return produce(state, draft => {
        draft.open.pad = action.pad;
      })
    default:
      return state
  }
}

const Combined = combineReducers({
  selection
})

export default Combined

import React from 'react';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const Profile = React.lazy(() => import('./views/user/Profile'));
const Delete = React.lazy(() => import('./views/user/Delete'));

const ChartPredictions = React.lazy(() => import('./views/predictions/ChartPredictions'));
const ChatPredictions = React.lazy(() => import('./views/predictions/ChatPredictions'));
const PeriodPredictions = React.lazy(() => import('./views/predictions/PeriodPredictions'));
const SamhitaPredictions = React.lazy(() => import('./views/predictions/ChartSamhita'));

const BirthCorrection = React.lazy(() => import('./views/tools/BirthCorrection'));
const EventsCalibration = React.lazy(() => import('./views/tools/EventsCalibration'));
const ZodiacCalibration = React.lazy(() => import('./views/tools/ZodiacCalibration'));
const BioCalibration = React.lazy(() => import('./views/tools/BioCalibration'));

const SV = React.lazy(() => import('./views/calculations/Saptavarga'));
const AV = React.lazy(() => import('./views/calculations/Ashtakvarga'));
const SB = React.lazy(() => import('./views/calculations/Shadbala'));
const GO = React.lazy(() => import('./views/calculations/Gochar'));
const Dasha = React.lazy(() => import('./views/calculations/Dasha'));
const Shadows = React.lazy(() => import('./views/calculations/Shadows'));

const PublicLink = React.lazy(() => import('./views/social/PublicLink'));
const FriendChart = React.lazy(() => import('./views/social/FriendChart'));
const PublicPage = React.lazy(() => import('./pages/PublicPage'));

const StripeSuccess = React.lazy(() => import('./views/billing/StripeSuccess'));
const StripeCancel = React.lazy(() => import('./views/billing/StripeCancel'));
const ProFeaturesPricing = React.lazy(() => import('./views/billing/ProFeaturesPricing'));
const ProFeaturesStatus = React.lazy(() => import('./views/billing/ProFeaturesStatus'));

const routes = [
  { path: '/', exact: true, name:  'route.home' , component: Dashboard },
  { path: '/dashboard', exact: true,  name: 'route.dashboard', component: Dashboard },
  { path: '/profile', exact: true,  name:  'route.profile', component: Profile },
  { path: '/deactivate', exact: true,  name:  'route.deactivate', component: Delete },

  { path: '/share', exact: true,  name:  'route.share', component: PublicLink },

  { path: '/predictions/chat', exact: true,  name:  'route.chatPredictions', component: ChatPredictions },
  { path: '/predictions/chart', exact: true,  name:  'route.chartPredictions', component: ChartPredictions },
  { path: '/predictions/period', exact: true,  name:  'route.periodPredictions', component: PeriodPredictions },
  { path: '/predictions/match', exact: true,  name:  'route.samhitaPredictions', component: SamhitaPredictions },

  { path: '/tools/rectification', exact: true,  name:  'route.rectification', component: BirthCorrection },
  { path: '/tools/calibration/events', exact: true,  name:  'route.calibration.events', component: EventsCalibration },
  { path: '/tools/calibration/zodiac', exact: true,  name:  'route.calibration.zodiac', component: ZodiacCalibration },
  { path: '/tools/calibration/biodata', exact: true,  name:  'route.calibration.bio', component: BioCalibration },
  
  { path: '/horoscope/sv', exact: true,  name:  'route.SV', component: SV },
  { path: '/horoscope/av', exact: true,  name:  'route.AV', component: AV },
  { path: '/horoscope/sb', exact: true,  name:  'route.SB', component: SB },
  { path: '/horoscope/dasha', exact: true,  name:  'route.dasha', component: Dasha },
  { path: '/horoscope/shadows', exact: true,  name:  'route.shadows', component: Shadows },
  { path: '/horoscope/transits', exact: true,  name:  'route.transits', component: GO },
  
  { path: '/social/chart/:id', exact: true,  name:  'route.friendChart', component: FriendChart },
  { path: '/public/chart/:id', exact: true,  name:  'route.publicChart', component: PublicPage },

  { path: '/payment/success', exact: true,  name:  'route.paySuccess', component: StripeSuccess },
  { path: '/payment/cancel', exact: true,  name:  'route.payFail', component: StripeCancel },
  { path: '/billing/pricing', exact: true,  name:  'route.advanced', component: ProFeaturesPricing },
  { path: '/billing/status', exact: true,  name:  'route.advanced', component: ProFeaturesStatus }
];

export default routes;

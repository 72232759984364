import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { chartActions } from '../../../store/actions/chartActions';
import CIcon from '@coreui/icons-react';
import {
  cilSend, cilReload, cilChatBubble, cilX
} from '@coreui/icons';
import { CSpinner } from '@coreui/react';
import styled from 'styled-components';
import DownloadPDFButton from './DownloadPdfButton';
import {
  PREDICTIONS_CHART_ROUTE,
  PREDICTIONS_PERIOD_ROUTE,
  HOROSCOPE_SHADOWS_ROUTE,
  DASHBOARD_ROUTE,
  HOME_ROUTE,
  TRANSITS_ROUTE,
  DASHA_ROUTE
} from './contexts';
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';


const planets = {
  "Ju": "Jupiter",
  "Ke": "Ketu",
  "Ma": "Mars",
  "Me": "Mercury",
  "Mo": "Moon",
  "Ra": "Rahu",
  "Sa": "Saturn",
  "Su": "Sun",
  "Ve": "Venus"
};


const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.user === 'user' ? 'flex-end' : 'flex-start'};
  margin-bottom: 8px;
`;

const MessageBubble = styled.div`
  max-width: 100%;
  padding: 8px 12px; /* Increased padding */
  margin: 4px;
  background-color: ${props => props.user === 'user' ? '#007aff' : '#fff'};
  color: ${props => props.user === 'user' ? '#fff' : '#000'};
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-wrap; /* Added to preserve newline characters and other formatting */
  @media (max-width: 480px) {
    max-width: 100%; /* Make bubbles full width on mobile */
    border-radius: 12px; /* Optionally adjust border-radius for mobile */
  }
`;

const InputArea = styled.textarea`
  width: 100%; // Should be 100% to fill container
  height: 100%; // Should be 100% to fill container
  padding: 8px 40px 8px 8px; // Padding on the right for the button
  margin: 0; // No margin to ensure it stays flush
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: none;
  font-size: 16px;
  line-height: 1.5;
  box-shadow: none;
  position: relative;
  display: block;
  bottom: 0;
`;


const ActionButton = styled.button`
  position: absolute;
  right: 10px; // Close to the right edge of InputArea
  bottom: 10px; // Close to the bottom edge of InputArea
  width: 40px; // Fixed width for circle
  height: 40px; // Fixed height for circle
  background-color:  #007bff;
  border: none;
  border-radius: 50%; // Circular shape
  width: 40px; // Width of the button
  height: 40px; // Height of the button, equal to width for circle
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;

  &:hover {
    background-color: #005ecb; // Darken button on hover
  }

  &:active {
    background-color: #004bad; // Further darken button on active/focus
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  svg {
    width: 20px; // Icon width
    height: 20px; // Icon height, equal to width
    color: white;
  }
`;

const InputButtonContainer = styled.div`
  flex: 0 0 auto; // Does not grow or shrink, based on its content's height
  position: relative;
  padding: 0.25em; // Padding relative to the current font size
  height: 8em; // Fixed height
  margin-bottom: 0.5em; // Margin at the bottom
`;

const MessagesContainer = styled.div`
  flex: 1 1 auto; // Takes up the remaining space
  overflow-y: auto; // Allows for scrolling within this container
  padding: 0.25em; // Padding relative to the current font size
  background-color: #e5e5ea;
  border-radius: 0.5em; // Border radius relative to font size
  margin-bottom: 1em; // Bottom margin relative to font size
  max-height: calc(100% - 13em); // Upper limit to growth

  @media (min-width: 768px) { /* md breakpoint adjustments */
  max-height: calc(100% - 10em);
  }

  // Styles to hide scrollbar
  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for WebKit browsers
  }

  // Standard way to hide scrollbar in Firefox and others
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none;  // Internet Explorer 
`;


const FullHeightDiv = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  height: 100vh;
  @media (min-width: 768px) { /* md breakpoint adjustments */
    height: 90vh;
  }

  @media (min-width: 1200px) { /* xl breakpoint adjustments */
    height: 80vh; // Set fixed height at 90% of viewport height
  }
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; // Firefox
  -ms-overflow-style: none;
`;

const ConfirmationSection = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; // Ensure it spans the full width
  background-color: white; // Ensure it's opaque so the chat doesn't show through
  transition: transform 0.3s ease-in-out;
  transform: translateY(${props => props.show ? '0%' : '100%'}); // Adjust based on visibility
  z-index: 1000; // Higher than the chat content
  padding: 16px; // Provide some padding
  box-shadow: 0px -2px 8px rgba(0,0,0,0.15); // Visual separation
  height: 22em; // Set height to 20em
  display: flex;
  flex-direction: column;
  justify-content: space-around; // Evenly space children vertically
  align-items: center; // Center children horizontally
`;


const StyledButton = styled.button`
  padding: 10px 10px; // Balanced padding for better visual appeal
  margin: 3px; // Uniform margin around buttons
  background-color: #007bff; // Bright blue color
  color: white;
  border: none;
  border-radius: 20px; // Rounded corners
  cursor: pointer;
  font-size: 16px; // Sufficient font size for readability
  width: 12em; // Consistent width for all buttons
  text-align: center; // Center text within the button
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; // Darker blue on hover
  }
`;


const MessageText = styled.p`
  color: #333;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px; // Add some space below the text
`;

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

// Component
const ChartAnalysisBot = ({ onClose, context }) => {
  const dispatch = useDispatch();
  const messages = useSelector(state => state.astro.chat.messages);
  const isLoading = useSelector(state => state.astro.chat.isLoading);
  const error = useSelector(state => state.astro.chat.error);
  const question = useSelector(state => state.astro.chat.question);
  const active = useSelector(state => state.billing.billingStatus.active);
  const period = useSelector(state => state.ui.period);
  const dasha = useSelector(state => state.ui.dasha);
  const periodanalysis = useSelector(state => state.astro.charts.periodanalysis)
  const shadows = useSelector(state => state.astro.charts.birth.shadows)
  const astroscore = useSelector(state => state.astro.charts.transit.astroScore)
  const panchang = useSelector(state => state.astro.charts.transit.metadata.panchang)
  const transits = useSelector(state => state.astro.charts.transit.D1)

  const [localQuestion, setLocalQuestion] = useState('');
  const [placeholder, setPlaceholder] = useState('Ask me anything...');
  const [initialMessage, setInitialMessage] = useState('');
  const [contextData, setContextData] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);

  const chatContainerRef = useRef(null);
  const quota = useSelector(state => state.billing.billingStatus.subscription?.quota) || 0;


  useEffect(() => {
    let configPlaceholder, configInitialMessage, configGetData;

    switch (context.route) {
      case PREDICTIONS_CHART_ROUTE: {
        configPlaceholder = 'What does my horoscope say about my career?';
        configInitialMessage = 'Dive into AI-powered Kundali reading! What would you like to know about your chart? Is it regarding career, health, relationships or something else?';
        configGetData = {};
        break;
      }
      case PREDICTIONS_PERIOD_ROUTE: {
        configPlaceholder = 'Which dates would be good for travel during this period?';
        configInitialMessage = `Dive into Gochara Analysis for the period ${monthNames[period.month]} ${period.year}. What specific influences are you curious about? Are you planning a trip, an event or something else, what would you like to ask about this period?`;
        configGetData = { when: { month: period.month, year: period.year }, events: periodanalysis.events };
        break;
      }
      case HOROSCOPE_SHADOWS_ROUTE: {
        configPlaceholder = 'What is the impact of Gulika in my chart?';
        configInitialMessage = 'Explore the effects of shadow planets. Do you feel something is not right? Do you experience a sense of unease or discomfort? Ask about upgrahas and shadows in your chart.';
        configGetData = { shadows };
        break;
      }
      case DASHA_ROUTE: {
        const mdPlanet = dasha.md.planet ? planets[dasha.md.planet] : dasha.md.label;
        const adPlanet = dasha.ad.planet ? planets[dasha.ad.planet] : dasha.ad.label;
        const padPlanet = dasha.pad.planet ? planets[dasha.pad.planet] : dasha.pad.label;
        configPlaceholder = 'What will happen during this dasha?';
        configInitialMessage = `Explore your Pratiantardasha of ${padPlanet} inside outer period (${mdPlanet} - ${adPlanet}) in detail. Are you curious about the cosmic influences during this dasha? Feel free to ask anything about this dasha.`;
        configGetData = { dasha };
        break;
      }
      case TRANSITS_ROUTE: {
        configPlaceholder = 'Will I get a job this week?';
        configInitialMessage = `Your question will take birth the exact moment you ask. It will have it's own birthchart and destiny. What question do you have? I will answer based on the Vedic Jyotish practice of Prasna Tantra.`;
        configGetData = { transits, panchang };
        break;
      }
      case DASHBOARD_ROUTE:
      case HOME_ROUTE: {
        const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        configPlaceholder = 'Is now a good time to sign a contract?';
        configInitialMessage = `Your live astro-score and panchang changes minute to minute! What astrological insights are you seeking right now at ${currentTime}? Ask about your astro-score or panchang details.`;
        configGetData = { astroscore, panchang };
        break;
      }
      default: {
        configPlaceholder = 'Default Placeholder';
        configInitialMessage = 'Default Message';
        configGetData = {};
      }
    }

    setPlaceholder(configPlaceholder);
    setInitialMessage(configInitialMessage);
    setContextData(configGetData);

    if (chatContainerRef.current) {
      const scrollElement = chatContainerRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [context, messages, dasha, astroscore, panchang, period.month, period.year, periodanalysis.events, shadows, transits]);

  useEffect(() => {
    if (error) {
      setLocalQuestion(question);
    }
  }, [error, question]);


  const handleAskClick = () => {
    if (localQuestion.trim() !== '' && quota > 0) {
      dispatch(chartActions.askChatQuestion(localQuestion, context.serverLogicalName, contextData));
      setLocalQuestion('');
    }
  };

  return (
    <CCard className='m-0 h-100 p-0'>
      <CCardHeader className="d-flex justify-content-between align-items-center bg-info text-white px-2">
        <span style={{ lineHeight: 1 }} className="d-flex align-items-center">
          <CIcon icon={cilChatBubble} size="xl" /><span className='ms-2'>AI Chat - {quota} questions left</span>
        </span>
        <div className="d-flex align-items-center">
          <CButton onClick={() => setShowConfirmation(true)} color='light' className='p-0 m-0' variant='ghost' style={{ lineHeight: 1 }}>
            <CIcon icon={cilX} size="lg" className='m-0' />
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody className='h-100 p-2 m-0'>
        <FullHeightDiv>
          <MessagesContainer ref={chatContainerRef}>
            <MessageBubble role="assistant">
              {initialMessage}
            </MessageBubble>
            {messages && messages.map((msg, index) => (
              <Message key={index} user={msg.user}>
                <MessageBubble user={msg.role}>{msg.content}</MessageBubble>
              </Message>
            ))}
          </MessagesContainer>

          <InputButtonContainer>
            <InputArea
              placeholder={isLoading ? "Fetching answer..." : placeholder}
              value={localQuestion}
              onChange={(e) => setLocalQuestion(e.target.value)}
              disabled={isLoading || !active}
              rows="3"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleAskClick();
                }
              }}
            />
            <ActionButton onClick={handleAskClick} disabled={isLoading || quota === 0}>
              {isLoading ? (
                <CSpinner color="primary" className='bg-white' />
              ) : error ? (
                <>
                  <CIcon icon={cilReload} size="lg" />
                </>
              ) : (
                <>
                  <CIcon icon={cilSend} size="lg" />
                </>
              )}
            </ActionButton>
          </InputButtonContainer>
          {showConfirmation && (
            <ConfirmationSection show={showConfirmation}>
              <MessageText>We do not save chat history on the server. You can download transcript before closing.</MessageText>

              <DownloadPDFButton title={context.title} messages={messages} isLoading={isLoading} onClose={onClose} />

              <StyledButton onClick={() => {
                dispatch(chartActions.clearChatHistory());
                setShowConfirmation(false);
                onClose();
              }}>Clear Chat</StyledButton>

              <StyledButton onClick={() => {
                setShowConfirmation(false);
              }}>Continue Chat</StyledButton>


            </ConfirmationSection>

          )}
        </FullHeightDiv>
      </CCardBody>
    </CCard>
  );
};

export default ChartAnalysisBot;

import { rectificationActions } from '../actions/rectificationActions';
import { combineReducers } from 'redux';
import produce from 'immer';

const defaultQuestionState = {
    fetching: false,
    events: undefined,
}

const defaultResponseState = {
    list: [],
}

const defaultCorrectionFlow = {
    type: 1,
}

const defaultAnalysisState = {
    fetching: false,
    optimalChange: undefined
}

const questions = (state = defaultQuestionState, action) => {
    switch (action.type) {
        case rectificationActions.RECTIFICATION_EVENT_REQUEST:
            return produce(state, draft => {
                draft.fetching = true;
            })
        case rectificationActions.RECTIFICATION_EVENT_SUCCESS:
            return produce(state, draft => {
                draft.events = action.info;
                draft.fetching = false;
            })
        case rectificationActions.RECTIFICATION_EVENT_ERROR:
            return produce(state, draft => {
                draft.fetching = false;
        })
        case rectificationActions.RECTIFICATION_EVENT_CLEAR:
            return defaultQuestionState;
        default:
            return state
    }
}

const analysis = (state = defaultAnalysisState, action) => {
    switch (action.type) {
        case rectificationActions.ANALYSIS_REQUEST:
            return produce(state, draft => {
                draft.fetching = true;
            })
        case rectificationActions.ANALYSIS_SUCCESS:
            return produce(state, draft => {
                draft.optimalChange = action.info;
                draft.fetching = false;
            })
        case rectificationActions.ANALYSIS_ERROR:
            return produce(state, draft => {
                draft.fetching = false;
        })
        case rectificationActions.ANALYSIS_CLEAR:
            return defaultAnalysisState;
        default:
            return state
    }
}

const responses = (state = defaultResponseState, action) => {
    switch (action.type) {
        case rectificationActions.RECTIFICATION_RESPONSE_SUCCESS:
            return produce(state, draft => {
                let newResponse = [...draft.list, action.info]
                draft.list = newResponse;
            })
        case rectificationActions.RECTIFICATION_CLEAR:
            return produce(state, draft => {
                draft.list = [];
            })
        default:
            return state
    }
}

const flow = (state = defaultCorrectionFlow, action) => {
    switch (action.type) {
        case rectificationActions.SET_CORRECTION_FLOW:
            return produce(state, draft => {
                draft.type = action.number;
            })
        default:
            return state
    }
}

const Combined = combineReducers({
    questions, responses, analysis, flow
})

export default Combined

import API from "../../utility/AdminAPI"

const CMS_BEGETCONTENT_REQUEST= 'CMS::BEGETCONTENT::REQUEST'
const CMS_BEGETCONTENT_ERROR= 'CMS::BEGETCONTENT::ERROR'
const CMS_BEGETCONTENT_SUCCESS= 'CMS::BEGETCONTENT::SUCCESS'

const CMS_BEGETCONTENTITEM_REQUEST= 'CMS::BEGETCONTENTITEM::REQUEST'
const CMS_BEGETCONTENTITEM_ERROR= 'CMS::BEGETCONTENTITEM::ERROR'
const CMS_BEGETCONTENTITEM_SUCCESS= 'CMS::BEGETCONTENTITEM::SUCCESS'

const CMS_BEGETCONTENTAUDIO_REQUEST= 'CMS::BEGETCONTENTAUDIO::REQUEST'
const CMS_BEGETCONTENTAUDIO_ERROR= 'CMS::BEGETCONTENTAUDIO::ERROR'
const CMS_BEGETCONTENTAUDIO_SUCCESS= 'CMS::BEGETCONTENTAUDIO:SUCCESS'

const CMS_FEGETCONTENT_REQUEST= 'CMS::FEGETCONTENT::REQUEST'
const CMS_FEGETCONTENT_ERROR= 'CMS::FEGETCONTENT::ERROR'
const CMS_FEGETCONTENT_SUCCESS= 'CMS::FEGETCONTENT::SUCCESS'

const CMS_FEGETCONTENTITEM_REQUEST= 'CMS::FEGETCONTENTITEM::REQUEST'
const CMS_FEGETCONTENTITEM_ERROR= 'CMS::FEGETCONTENTITEM::ERROR'
const CMS_FEGETCONTENTITEM_SUCCESS= 'CMS::FEGETCONTENTITEM::SUCCESS'

const CMS_SAVEBEITEM_REQUEST= 'CMS::SETBECONTENT::REQUEST'
const CMS_SAVEBEITEM_ERROR= 'CMS::SETBECONTENT::ERROR'
const CMS_SAVEBEITEM_SUCCESS= 'CMS::SETBECONTENT::SUCCESS'

const CMS_SAVEFEITEM_REQUEST= 'CMS::SETFECONTENT::REQUEST'
const CMS_SAVEFEITEM_ERROR= 'CMS::SETFECONTENT::ERROR'
const CMS_SAVEFEITEM_SUCCESS= 'CMS::SETFECONTENT::SUCCESS'

const ROOT_GETLANG_REQUEST= 'ROOT::GETLANG::REQUEST'
const ROOT_GETLANG_ERROR= 'ROOT::GETLANG::ERROR'
const ROOT_GETLANG_SUCCESS= 'ROOT::GETLANG::SUCCESS'

const ROOT_ADDLANG_REQUEST= 'ROOT::ADDLANG::REQUEST'
const ROOT_ADDLANG_ERROR = 'ROOT::ADDLANG::ERROR'
const ROOT_ADDLANG_SUCCESS= 'ROOT::ADDLANG::SUCCESS'

const ROOT_DELLANG_REQUEST= 'ROOT::DELLANG::REQUEST'
const ROOT_DELLANG_ERROR = 'ROOT::DELLANG::ERROR'
const ROOT_DELLANG_SUCCESS= 'ROOT::DELLANG::SUCCESS'

const ROOT_ADDTXNS_REQUEST= 'ROOT::ADDTXNS::REQUEST'
const ROOT_ADDTXNS_ERROR = 'ROOT::ADDTXNS:ERROR'
const ROOT_ADDTXNS_SUCCESS= 'ROOT::ADDTXNS::SUCCESS'

const ROOT_DELTXNS_REQUEST= 'ROOT::DELTXNS::REQUEST'
const ROOT_DELTXNS_ERROR = 'ROOT::DELTXNS::ERROR'
const ROOT_DELTXNS_SUCCESS= 'ROOT::DELTXNS::SUCCESS'

const ROOT_GETTXNS_REQUEST= 'ROOT::GETTXNS::REQUEST'
const ROOT_GETTXNS_ERROR = 'ROOT::GETTXNS::ERROR'
const ROOT_GETTXNS_SUCCESS= 'ROOT::GETTXNS::SUCCESS'

const CMS_FE_SETLANG = 'CMS::FE_SETLANG'
const CMS_FE_SETTAB = 'CMS::FE_SETTAB'
const CMS_BE_SETLANG = 'CMS::BE_SETLANG'
const CMS_BE_SETTAB = 'CMS::BE_SETTAB'
const CMS_BE_SETITEM = 'CMS::BE_SETITEM'
const CMS_BE_SETITEMEDITABLE = 'CMS::BE_SETITEMEDITABLE'
const CMS_FE_SETITEM = 'CMS::FE_SETITEM'
const CMS_FE_SETITEMEDITABLE = 'CMS::FE_SETITEMEDITABLE'

const getContentBE = (astrologer, category) => {
    return dispatch => {
        dispatch(request())
        API.getContentByCategory(astrologer, category).then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: CMS_BEGETCONTENT_REQUEST } }
    function error() { return { type: CMS_BEGETCONTENT_ERROR } }
    function success(content) { return { type: CMS_BEGETCONTENT_SUCCESS, content } }
}

const getContentItemBE = (astrologer, id) => {
    return dispatch => {
        dispatch(request())
        API.getContentById(astrologer, id).then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: CMS_BEGETCONTENTITEM_REQUEST } }
    function error() { return { type: CMS_BEGETCONTENTITEM_ERROR } }
    function success(saved) { return { type: CMS_BEGETCONTENTITEM_SUCCESS, saved } }
}

const getContentAudioBE = (astrologer, lang, id, content) => {
    return dispatch => {
        dispatch(request(id))
        API.listen(astrologer, lang, content).then(data => {
            dispatch(success(id, data.mp3))
        }).catch(err => {
            dispatch(error());
        })
    };

    function request(id) { return { type: CMS_BEGETCONTENTAUDIO_REQUEST, id } }
    function error(id) { return { type: CMS_BEGETCONTENTAUDIO_ERROR, id } }
    function success(id, mp3) { return { type: CMS_BEGETCONTENTAUDIO_SUCCESS, id, mp3 } }
}


const getContentFE = (astrologer, category) => {
    return dispatch => {
        dispatch(request())
        API.getContentByCategory(astrologer, category).then(data => {
            dispatch(success(data))
        }).catch(err => {
            console.log('err :: ' + err)
            dispatch(error());
        })
    };

    function request() { return { type: CMS_FEGETCONTENT_REQUEST } }
    function error() { return { type: CMS_FEGETCONTENT_ERROR } }
    function success(content) { return { type: CMS_FEGETCONTENT_SUCCESS, content, category } }
}

const getContentItemFE = (astrologer, id) => {
    return dispatch => {
        dispatch(request())
        API.getContentById(astrologer, id).then(data => {
            dispatch(success(data))
        }).catch(err => {
            console.log('err :: ' + err)
            dispatch(error());
        })
    };

    function request() { return { type: CMS_FEGETCONTENTITEM_REQUEST } }
    function error() { return { type: CMS_FEGETCONTENTITEM_ERROR } }
    function success(saved) { return { type: CMS_FEGETCONTENTITEM_SUCCESS, saved } }
}

const saveContentItemBE = (astrologer, language, id, content) => {
    return dispatch => {
        dispatch(request(id))
        API.saveContentById(astrologer, language, id, content).then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error(id));
        })
    };

    function request(id) { return { type: CMS_SAVEBEITEM_REQUEST, id } }
    function error(id) { return { type: CMS_SAVEBEITEM_ERROR, id } }
    function success(saved) { return { type: CMS_SAVEBEITEM_SUCCESS, saved } }
}

const saveContentItemFE = (astrologer, language, id, content) => {
    return dispatch => {
        dispatch(request(id))
        API.saveContentById(astrologer, language, id, content).then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error(id));
        })
    };

    function request(id) { return { type: CMS_SAVEFEITEM_REQUEST, id } }
    function error(id) { return { type: CMS_SAVEFEITEM_ERROR, id } }
    function success(saved) { return { type: CMS_SAVEFEITEM_SUCCESS, saved } }
}

const getSupportedLangs = () => {
    return dispatch => {
        dispatch(request())
        API.getSupportedLanguages('lagna').then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: ROOT_GETLANG_REQUEST } }
    function error() { return { type: ROOT_GETLANG_ERROR } }
    function success(langs) { return { type: ROOT_GETLANG_SUCCESS, langs } }
}

const addSupportedLang = (lang) => {
    return dispatch => {
        dispatch(request())
        API.addSupportedLanguage('lagna', lang).then(data => {
            dispatch(success())
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: ROOT_ADDLANG_REQUEST } }
    function error() { return { type: ROOT_ADDLANG_ERROR } }
    function success() { return { type: ROOT_ADDLANG_SUCCESS } }
}

const delSupportedLang = (lang) => {
    return dispatch => {
        dispatch(request())
        API.delSupportedLanguage('lagna', lang).then(data => {
            dispatch(success())
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: ROOT_DELLANG_REQUEST } }
    function error() { return { type: ROOT_DELLANG_ERROR } }
    function success() { return { type: ROOT_DELLANG_SUCCESS } }
}

const addTranslationNamespace = (ns) => {
    return dispatch => {
        dispatch(request())
        API.addTranslationNS(ns).then(data => {
            dispatch(success())
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: ROOT_ADDTXNS_REQUEST } }
    function error() { return { type: ROOT_ADDTXNS_ERROR } }
    function success() { return { type: ROOT_ADDTXNS_SUCCESS } }
}

const delTranslationNamespace = (ns) => {
    return dispatch => {
        dispatch(request())
        API.delTranslationNS(ns).then(data => {
            dispatch(success())
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: ROOT_DELTXNS_REQUEST } }
    function error() { return { type: ROOT_DELTXNS_ERROR } }
    function success() { return { type: ROOT_DELTXNS_SUCCESS } }
}

const getTranslationNamespaces = (lng) => {
    return dispatch => {
        dispatch(request(lng))
        API.getContentByCategory('lagna', `lagna-${lng}-translations`).then(data => {
            dispatch(success(lng, data))
        }).catch(err => {
            dispatch(error());
        })
    };

    function request(lang) { return { type: ROOT_GETTXNS_REQUEST, lang } }
    function error() { return { type: ROOT_GETTXNS_ERROR } }
    function success(lang, content) { return { type: ROOT_GETTXNS_SUCCESS , lang, content} }
}

const setActiveLangBE = (lang) => {
    return dispatch => {
        dispatch(request(lang))
    };
    function request(lang) { return { type: CMS_BE_SETLANG, lang } }
}

const setActiveTabBE = (tab, subtab) => {
    return dispatch => {
        dispatch(request(tab, subtab))
    };
    function request(tab, sub) { return { type: CMS_BE_SETTAB, tab, sub} }
}

const setActiveLangFE = (lang) => {
    return dispatch => {
        dispatch(request(lang))
    };
    function request(lang) { return { type: CMS_FE_SETLANG, lang } }
}

const setActiveTabFE = (tab) => {
    return dispatch => {
        dispatch(request(tab))
    };
    function request(tab) { return { type: CMS_FE_SETTAB, tab} }
}


const setBEItem = (id, content) => {
    return dispatch => {
        dispatch(request(id, content))
    };
    function request(id, content) { return { type: CMS_BE_SETITEM, id, content } }
}

const setBEItemEditable = (id) => {
    return dispatch => {
        dispatch(request(id))
    };
    function request(id) { return { type: CMS_BE_SETITEMEDITABLE, id } }
}

const setFEItem = (id, content) => {
    return dispatch => {
        dispatch(request(id, content))
    };
    function request(id, content) { return { type: CMS_FE_SETITEM, id, content } }
}

const setFEItemEditable = (id) => {
    return dispatch => {
        dispatch(request(id))
    };
    function request(id) { return { type: CMS_FE_SETITEMEDITABLE, id } }
}

export const adminActions = {
    CMS_BEGETCONTENT_REQUEST,
    CMS_BEGETCONTENT_ERROR,
    CMS_BEGETCONTENT_SUCCESS,

    CMS_FEGETCONTENT_REQUEST,
    CMS_FEGETCONTENT_ERROR,
    CMS_FEGETCONTENT_SUCCESS,

    CMS_BEGETCONTENTITEM_REQUEST,
    CMS_BEGETCONTENTITEM_ERROR,
    CMS_BEGETCONTENTITEM_SUCCESS,

    CMS_BEGETCONTENTAUDIO_REQUEST,
    CMS_BEGETCONTENTAUDIO_ERROR,
    CMS_BEGETCONTENTAUDIO_SUCCESS,

    CMS_FEGETCONTENTITEM_REQUEST,
    CMS_FEGETCONTENTITEM_ERROR,
    CMS_FEGETCONTENTITEM_SUCCESS,

    CMS_SAVEBEITEM_REQUEST,
    CMS_SAVEBEITEM_ERROR,
    CMS_SAVEBEITEM_SUCCESS,

    CMS_SAVEFEITEM_REQUEST,
    CMS_SAVEFEITEM_ERROR,
    CMS_SAVEFEITEM_SUCCESS,

    ROOT_GETLANG_REQUEST,
    ROOT_GETLANG_ERROR,
    ROOT_GETLANG_SUCCESS,

    ROOT_ADDLANG_REQUEST,
    ROOT_ADDLANG_ERROR,
    ROOT_ADDLANG_SUCCESS,

    ROOT_DELLANG_REQUEST,
    ROOT_DELLANG_ERROR,
    ROOT_DELLANG_SUCCESS,

    ROOT_ADDTXNS_REQUEST,
    ROOT_ADDTXNS_ERROR,
    ROOT_ADDTXNS_SUCCESS,

    ROOT_DELTXNS_REQUEST,
    ROOT_DELTXNS_ERROR,
    ROOT_DELTXNS_SUCCESS,

    ROOT_GETTXNS_REQUEST,
    ROOT_GETTXNS_ERROR,
    ROOT_GETTXNS_SUCCESS,

    CMS_FE_SETLANG,
    CMS_FE_SETTAB,

    CMS_BE_SETLANG,
    CMS_BE_SETTAB,

    CMS_BE_SETITEM,
    CMS_BE_SETITEMEDITABLE,
    CMS_FE_SETITEM,
    CMS_FE_SETITEMEDITABLE,

    getContentBE,
    getContentFE,
    getContentItemBE,
    getContentAudioBE,
    getContentItemFE,
    saveContentItemFE,
    saveContentItemBE,
    getSupportedLangs,
    addSupportedLang,
    delSupportedLang,
    addTranslationNamespace,
    delTranslationNamespace,
    getTranslationNamespaces,

    setActiveLangFE,
    setActiveTabFE,
    setActiveLangBE,
    setActiveTabBE,
    setBEItem,
    setBEItemEditable,
    setFEItem,
    setFEItemEditable
};


import { authActions } from '../actions/authActions';
import { combineReducers } from 'redux';
import produce from 'immer';

const defaultAuthState =
{
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  authenticated: false,
  loading: false,
  photo: '',
  provider: {
    type: '',
    id: '',
  },
  isRootAdmin: false,
  isCMSAdmin: false,
  languageGroups: []
}

const user = (state = defaultAuthState, action) => {
  switch (action.type) {
    case authActions.USER_CLEAR:
      return defaultAuthState
    case authActions.USER_FETCH:
      return produce(state, draft => {
        draft.authenticated = false;
        draft.loading = true;
      })
    case authActions.USER_SET:
      return produce(state, draft => {
        draft.firstName = action.user.attributes.given_name;
        draft.lastName = action.user.attributes.family_name;
        draft.email = action.user.attributes.email;
        draft.subject = action.user.attributes.sub;
        
        const groups = action.user.signInUserSession.accessToken.payload['cognito:groups'];
        if(groups){
          draft.groups = groups;
          if(groups.includes('admin')) draft.isRootAdmin = true;
          if(groups.includes('lagna')) draft.isCMSAdmin = true;
          const langGroups = groups.filter(function (ele) {
            return (ele !== 'lagna' && ele !== 'admin');
          });
          draft.languageGroups = langGroups;
        }
        if (action.user.attributes.identities !== undefined) {
          const identity = JSON.parse(action.user.attributes.identities);
          const type = identity[0].providerName;
          const id = identity[0].userId;
          draft.provider.type = type;
          draft.provider.id = id;
          draft.created = identity[0].dateCreated;
        }
        draft.photo = action.user.attributes.picture
        draft.authenticated = true;
        draft.loading = false;
      })
    default:
      return state
  }
}

const defaultCaptchaState =
{
  isBot: undefined,
  isVerifying: false
}

const captcha = (state = defaultCaptchaState, action) => {
  switch (action.type) {
    case authActions.RECAPTCHA_REQUEST:
      return produce(state, draft => {
        draft.isBot = undefined;
        draft.isVerifying = true;
      });
    case authActions.RECAPTCHA_FAILURE:
      return produce(state, draft => {
        draft.isBot = undefined;
        draft.isVerifying = false;
      });
    case authActions.RECAPTCHA_SUCCESS:
      return produce(state, draft => {
        draft.isBot = action.verification.isBot;
        draft.isVerifying = false;
      });
    default:
      return state;
  }
}

const Combined = combineReducers({
  user, captcha
})

export default Combined

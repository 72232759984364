import API from "../../utility/API";

const SET_CORRECTION_FLOW = 'CORRECTION::FLOW::SET';

const RECTIFICATION_EVENT_REQUEST = 'RECTIFICATION::EVENT::REQUEST';
const RECTIFICATION_EVENT_SUCCESS = 'RECTIFICATION::EVENT::SUCCESS';
const RECTIFICATION_EVENT_ERROR = 'RECTIFICATION::EVENT::ERROR';
const RECTIFICATION_EVENT_CLEAR = 'RECTIFICATION::EVENT::CLEAR';

const ANALYSIS_REQUEST = 'ANALYSIS:REQUEST';
const ANALYSIS_SUCCESS = 'ANALYSIS::SUCCESS';
const ANALYSIS_ERROR = 'ANALYSIS::ERROR';
const ANALYSIS_CLEAR = 'ANALYSIS::CLEAR';

const RECTIFICATION_RESPONSE_SUCCESS = 'RECTIFICATION::RESPONSE::SUCCESS';
const RECTIFICATION_CLEAR = 'RECTIFICATION::CLEAR';

const getRectificationEvents = (latitude, longitude, birthDate, name) => {
    return dispatch => {
        dispatch(request())
        API.getRectificationQuestions(latitude, longitude, birthDate, name).then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: RECTIFICATION_EVENT_REQUEST } }
    function success(info) { return { type: RECTIFICATION_EVENT_SUCCESS, info } }
    function failure(error) { return { type: RECTIFICATION_EVENT_ERROR, error } }
}

const analyzeRectificationEvents = (answers) => {
    return dispatch => {
        dispatch(request())
        API.getRectificationAnalysis(answers).then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: ANALYSIS_REQUEST } }
    function success(info) { return { type: ANALYSIS_SUCCESS, info } }
    function failure(error) { return { type: ANALYSIS_ERROR, error } }
}

const setCorrectionFlow = (flow) => {
    return dispatch => {
        dispatch(success(flow))
    }
    function success(number) { return { type: SET_CORRECTION_FLOW, number } }
}


const setRectificationResponses = (response) => {
    return dispatch => {
        dispatch(success(response))
    }
    function success(info) { return { type: RECTIFICATION_RESPONSE_SUCCESS, info } }
}

const clearRectification = () => {
    return dispatch => {
        dispatch(clearResult())
    }
    function clearResult() { return { type: RECTIFICATION_CLEAR}}
}

const clearRectificationEvents= () => {
    return dispatch => {
        dispatch(clearResult())
    }
    function clearResult() { return { type: RECTIFICATION_EVENT_CLEAR}}
}

const clearAnalysis= () => {
    return dispatch => {
        dispatch(clearResult())
    }
    function clearResult() { return { type: ANALYSIS_CLEAR}}
}

export const rectificationActions = {
    RECTIFICATION_EVENT_REQUEST,
    RECTIFICATION_EVENT_SUCCESS,
    RECTIFICATION_EVENT_ERROR,
    RECTIFICATION_RESPONSE_SUCCESS,
    RECTIFICATION_CLEAR,
    RECTIFICATION_EVENT_CLEAR,
    ANALYSIS_REQUEST,
    ANALYSIS_SUCCESS,
    ANALYSIS_ERROR,
    ANALYSIS_CLEAR,
    SET_CORRECTION_FLOW,

    getRectificationEvents,
    setRectificationResponses,
    clearRectification,
    analyzeRectificationEvents,
    clearAnalysis,
    clearRectificationEvents,
    setCorrectionFlow
};

const DASHA_SET_MD = 'DASHA::SET::MD'
const DASHA_SET_AD = 'DASHA::SET::AD'
const DASHA_SET_PAD = 'DASHA::SET::PAD'

const setMD = (val) => {
    return dispatch => {
        dispatch(setAD(null))
        dispatch(setPAD(null))
        dispatch(set(val))
    };
    function set(md) { return { type: DASHA_SET_MD, md } }
}

const setAD = (val) => {
    return dispatch => {
        dispatch(setPAD(null))
        dispatch(set(val))
    };
    function set(ad) { return { type: DASHA_SET_AD, ad } }
}

const setPAD = (val) => {
    return dispatch => {
        dispatch(set(val))
    };
    function set(pad) { return { type: DASHA_SET_PAD, pad } }
}


export const dashaActions = {
    setMD,
    setAD,
    setPAD,

    DASHA_SET_MD,
    DASHA_SET_AD,
    DASHA_SET_PAD
};


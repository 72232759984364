import { locationActions } from '../actions/locationActions';
import { combineReducers } from 'redux';
import produce from 'immer';

const defaultLocationState = {
    longitude: undefined,
    latitude: undefined,
    location: '',
    inProgress: false,
    isCached: false
}

const current = (state = defaultLocationState, action) => {
    switch (action.type) {
        case locationActions.LOCATION_REQUEST:
            return produce(state, draft => {
                draft.inProgress = true;
            })
        case locationActions.LOCATION_SUCCESS:
            return produce(state, draft => {
                draft.inProgress = false;
                draft.isCached = action.location.cached;
                draft.latitude = action.location.latitude;
                draft.longitude = action.location.longitude;
                draft.location = action.location.address;
            })
        case locationActions.FINE_ERROR:
            return produce(state, draft => {
                draft.inProgress = false;
            })
        case locationActions.CRUDE_ERROR:
            return produce(state, draft => {
                draft.inProgress = false;
            })
        case locationActions.STORAGE_ERROR:
            return produce(state, draft => {
                draft.inProgress = false;
                draft.longitude = -0.000500;
                draft.latitude = 51.476852;
                draft.location = 'Greenwich, London, UK';
            })
        default:
            return state
    }
}

const Combined = combineReducers({
    current
})

export default Combined

import React from 'react';
import styled from 'styled-components';

const H1 = styled.div`
    animation: blinker 1.0s infinite;
    font-family: sans-serif;
    font-size: 2em;
    @keyframes blinker {
        70% {
            opacity: 0;
        }
    }
`

let centerStyle = {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}

const Animate = ({color, message}) => {
    return (
        <H1 className={`text-center text-${color}`} style={centerStyle}>{message}</H1>
    )
}
export default Animate

import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CButtonGroup, CButton, CDropdownDivider, CDropdownHeader
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { authActions } from '../store/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { prefActions } from "../store/actions/prefActions"
import { cilCreditCard, cilFingerprint, cilLockLocked, cilSpeedometer, cilTrash } from '@coreui/icons'
import { BiUserCircle } from 'react-icons/bi';


const TheHeaderDropdown = () => {

  const dispatch = useDispatch()
  const birth_info = useSelector(state => state.astro.charts.birthinfo)
  const rawBD = useSelector(state => state.astro.charts.birth.metadata.date)
  const user = useSelector(state => state.auth.user)
  const preferences = useSelector(state => state.preferences)
  const logout = () => dispatch(authActions.signOutRequest())
  const { t } = useTranslation(['dashboard', 'main']);

  /*
  const setDb = (type) => {
    let newPrefs = { ...preferences }
    newPrefs.dashboard = type
    dispatch(prefActions.setPreferences(newPrefs))
  }
  */

  const setAdminMode = (mode) => {
    let newPrefs = { ...preferences }
    newPrefs.adminMode = mode
    dispatch(prefActions.setPreferences(newPrefs))
  }

  return (

    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <BiUserCircle size={32} />
      </CDropdownToggle>

      <CDropdownMenu className="pt-0" placement="bottom-end">

        <CDropdownHeader disabled={true} className="text-start my-auto font-weight-bold">
          <p className='my-1 py-0'>{user.email}</p>
          <small className='mt-1 py-0'>{rawBD}</small>
        </CDropdownHeader>

        <CDropdownDivider />
        <CDropdownItem component={NavLink} disabled={birth_info.complete === false} to='/profile'>
          <CIcon icon={cilFingerprint} className="me-2" />
          {t('route.profile')}
        </CDropdownItem>

        <CDropdownDivider />
        <CDropdownItem component={NavLink} disabled={birth_info.complete === false} to='/billing/status'>
          <CIcon icon={cilCreditCard} className="me-2" />
          {t('route.advanced')}
        </CDropdownItem>

        <CDropdownDivider />
        <CDropdownItem component={NavLink} disabled={birth_info.complete === false} to='/deactivate'>
          <CIcon icon={cilTrash} className="me-2 text-danger" />
          <span className='text-danger'>{t('route.deactivate')}</span>
        </CDropdownItem>

        <CDropdownDivider />

        {/*birth_info.complete === true ?
          <>
            <CDropdownItem  className="text-start font-weight-bold">
              <CIcon name="cil-speedometer" className="mfe-2" />
              {t('dashboard:modal.selectDashboard.choose')}
            </CDropdownItem>
            <div>
              <CButtonGroup className='ms-5 my-2'>
                <CButton className='p-0 px-1' size='sm' outline='true' color={preferences.dashboard === 1 ? 'info' : 'secondary'} onClick={() => setDb(1)} active={preferences.dashboard === 1}>{t('dashboard:modal.selectDashboard.simple.title')}</CButton>
                <CButton className='p-0 px-1' size='sm' outline='true' color={preferences.dashboard === 2 ? 'info' : 'secondary'} onClick={() => setDb(2)} active={preferences.dashboard === 2}>{t('dashboard:modal.selectDashboard.advanced.title')}</CButton>
              </CButtonGroup>
              <CDropdownItem divider />
            </div>
          </>
          :
          <>
          </>
        */}

        {user.isCMSAdmin &&
          <>
            <CDropdownItem className="text-start font-weight-bold">
              <CIcon icon={cilSpeedometer} className="mfe-2" />
              {t('main:containers.theHeader.nav.adminMode')}
            </CDropdownItem>

            <CButtonGroup className='ms-5 my-2'>
              <CButton className='p-0 px-1' size='sm' outline='true' color={preferences.adminMode === 'off' ? 'warning' : 'secondary'} onClick={() => setAdminMode('off')} active={preferences.adminMode === 'off'}>off</CButton>
              <CButton className='p-0 px-1' size='sm' outline='true' color={preferences.adminMode === 'on' ? 'warning' : 'secondary'} onClick={() => setAdminMode('on')} active={preferences.adminMode === 'on'}>on</CButton>
            </CButtonGroup>
            <CDropdownDivider />
          </>
        }

        <CDropdownItem onClick={() => { logout() }}>
          <CIcon icon={cilLockLocked} className="me-2" />
          {t('route.logout')}
        </CDropdownItem>

      </CDropdownMenu>
    </CDropdown>

  )
}

export default TheHeaderDropdown

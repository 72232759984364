import { uiActions } from '../actions/uiActions';
import { combineReducers } from 'redux';
import produce from 'immer';

const defaultUIState = {
    sidebarNarrow: false
}

const defaultPeriod = {
    month: (new Date()).getMonth(),
    year: (new Date()).getFullYear()
}

const defaultDasha = {
    md: undefined,
    ad: undefined,
    pad: undefined
}

const defaultChartPrediction = {
    advanced: true
}

const dashboard = (state = defaultUIState, action) => {
    switch (action.type) {
        case uiActions.UI_SIDEBAR_SETSHOW:
            return produce(state, draft => {
                draft.sidebarShow = action.sb;
            })
        case uiActions.UI_SIDEBAR_SETNARROW:
            return produce(state, draft => {
                draft.sidebarNarrow = action.sb;
            })
        default:
            return state
    }
}

const period = (state = defaultPeriod, action) => {
    switch (action.type) {
        case uiActions.UI_PERIODS_SETMONTH:
            return produce(state, draft => {
                draft.month = action.month;
            })
        case uiActions.UI_PERIODS_SETYEAR:
            return produce(state, draft => {
                draft.year = action.year;
            })
        case uiActions.UI_PERIODS_SETLOCATION:
            return produce(state, draft => {
                draft.location = action.location;
            })
        default:
            return state
    }
}

const dasha = (state = defaultDasha, action) => {
    switch (action.type) {
        case uiActions.UI_VSDASHA_SETMD:
            return produce(state, draft => {
                draft.md = action.md;
            })
        case uiActions.UI_VSDASHA_SETAD:
            return produce(state, draft => {
                draft.ad = action.ad;
            })
        case uiActions.UI_VSDASHA_SETPAD:
            return produce(state, draft => {
                draft.pad = action.pad;
            })
        default:
            return state
    }
}

const chartPredictions = (state = defaultChartPrediction, action) => {
    switch (action.type) {
        case uiActions.UI_CHART_PREDICTIONS_ADVANCED:
            return produce(state, draft => {
                draft.advanced = action.advanced;
            })
        default:
            return state
    }
}

const initialConsentState = {
    zodiacCalibration: false,
    chartRectification: false,
    eventCalibration: false,
    statusCalibration: false,
};

const consentCheckboxState = (state = initialConsentState, action) => {
    switch (action.type) {
        case uiActions.SET_CONSENT_CHECKBOX_STATE:
            return produce(state, draft => {
                draft[action.checkboxName] = action.isChecked;
            });
        case uiActions.RESET_CONSENT_CHECKBOX_STATE:
            return initialConsentState;
        default:
            return state;
    }
};

const Combined = combineReducers({
    dashboard, period, dasha, chartPredictions, consentCheckboxState
})

export default Combined

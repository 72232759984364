import React, { Suspense, useEffect, useState } from 'react'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index'

import Loading from "../views/widgets/Loading"
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import { authActions } from '../store/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import ChatModal from '../views/predictions/components/ChatModal'
import ChatButton from '../views/predictions/components/ChatButton'

import { CHATBOTS } from '../views/predictions/components/contexts';

const TheLayout = () => {
  const dispatch = useDispatch()
  const verifyRecaptcha = (exe, userAction) => dispatch(authActions.verifyRecaptcha(exe, userAction))
  const { executeRecaptcha } = useGoogleReCaptcha();
  const location = useLocation();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showSalesPitch, setShowSalesPitch] = useState(false);
  const billingStatus = useSelector(state => state.billing.billingStatus);
  const { subscription, active } = billingStatus;

  useEffect(() => {
    verifyRecaptcha(executeRecaptcha, "SiteLoaded")
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;  
    if (isChatOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalStyle;
    }
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isChatOpen]);

  const handleChatButtonClick = () => {
    if (!active || !subscription?.paymentDetails?.planType || subscription?.quota === 0) {
      setShowSalesPitch(true);
    } else {
      setShowSalesPitch(false);
    }
    setIsChatOpen(true);
  };

  const routesWithChat = Object.values(CHATBOTS).map(bot => bot.route);
  const currentChatbot = Object.values(CHATBOTS).find(bot => bot.route === location.pathname);

  return (
    <Suspense fallback={<Loading color='dark' message='Calculating' />}>
      <div>
        <TheSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <TheHeader />
          <div className="body flex-grow-1 p-0 p-md-2 p-lg-3 pb-5">
            <TheContent />
          </div>
          <TheFooter />
        </div>
        <ChatButton onClick={handleChatButtonClick} show={routesWithChat.includes(location.pathname)}s/>
        <ChatModal showSalesPitch={showSalesPitch} isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} context={currentChatbot || {}} />
      </div>
    </Suspense>
  )
}

export default TheLayout

import { prefActions } from '../actions/prefActions';
import produce from 'immer';

const defaultPreferences = {
    dashboard: undefined,
    adminMode: 'off',
    zodiacCalibration: 'K',
    publicStatus: 'F',
    isLoading: false
}

const preferences = (state = defaultPreferences, action) => {
    switch (action.type) {
        case prefActions.PREFERENCES_GET_REQUEST:
            return produce(state, draft => {
                draft.isLoading = true;
            })
        case prefActions.PREFERENCES_GET_SUCCESS:
            return produce(state, draft => {
                draft.isLoading = false;
                if(action.prefs.dashboard) draft.dashboard = action.prefs.dashboard;
                if(action.prefs.adminMode) draft.adminMode = action.prefs.adminMode;
                if(action.prefs.publicStatus) draft.publicStatus = action.prefs.publicStatus; 
                if(action.prefs.zodiacCalibration && (action.prefs.zodiacCalibration === 'K' || action.prefs.zodiacCalibration === 'C') ) {
                    draft.zodiacCalibration = action.prefs.zodiacCalibration;
                }
            })
        case prefActions.PREFERENCES_GET_ERROR:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        case prefActions.PREFERENCES_SAVE_REQUEST:
            return produce(state, draft => {
                draft.isLoading = true;
            })
        case prefActions.PREFERENCES_SAVE_SUCCESS:
            return produce(state, draft => {
                draft.isLoading = false;
                if(action.prefs.dashboard) draft.dashboard = action.prefs.dashboard;
                if(action.prefs.adminMode) draft.adminMode = action.prefs.adminMode;
                if(action.prefs.publicStatus) draft.publicStatus = action.prefs.publicStatus; 
                if(action.prefs.zodiacCalibration && (action.prefs.zodiacCalibration === 'K' || action.prefs.zodiacCalibration === 'C') ) {
                    draft.zodiacCalibration = action.prefs.zodiacCalibration;
                }
            })
        case prefActions.PREFERENCES_SAVE_ERROR:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        default:
            return state
    }
}

export default preferences

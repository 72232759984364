
import API from "../../utility/API"
import store from 'store'

const LOCATION_REQUEST = 'INPUT::LOCATION_REQUEST'
const LOCATION_SUCCESS = 'INPUT::LOCATION_SUCCESS'
const FINE_ERROR = 'INPUT::LOCATION_ERROR_FINE'
const CRUDE_ERROR = 'INPUT::LOCATION_ERROR_CRUDE'
const STORAGE_ERROR = 'INPUT::LOCATION_ERROR_STORAGE'

const getFineLocation = () => {
    return dispatch => {
        dispatch(request())

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const lat = position.coords.latitude
                const long = position.coords.longitude
                const address = API.getFineLocation({ latitude: lat, longitude: long })
                const res = {
                    longitude: long,
                    latitude: lat,
                    address: address,
                    cached: false
                }
                dispatch(success(res))
                setStoredLocation(res)
            }, error => {
                dispatch(failure(error.code))
                getCrudeLocation()
            })
        } else {
            dispatch(failure('NOT_SUPPORTED'))
            getCrudeLocation()
        }
    }

    function request() { return { type: LOCATION_REQUEST } }
    function success(location) { return { type: LOCATION_SUCCESS, location } }
    function failure(error) { return { type: FINE_ERROR, error } }

}

const preferStoredLocation = () => {
    return dispatch => {
        let location = store.get('LOCATION')
        if (location && location.latitude && location.longitude && location.address) {
            location.cached = true;
            console.log('using location from local storage ' + JSON.stringify(location))
            dispatch(success(location))
        } else {
            dispatch(getCrudeLocation())
        }
    }
    function success(location) { return { type: LOCATION_SUCCESS, location } }
}


const getCrudeLocation = () => {
    return dispatch => {
        dispatch(request())
        let location = {}

        API.getCrudeLocation()
            .then(data => {
                location.latitude = data.latitude;
                location.longitude = data.longitude;
                location.address = data.address;
                location.cached = false;
                dispatch(success(location))
                console.log('location online ' + JSON.stringify(location))

                location.cached = true;
                setStoredLocation(location)
            })
            .catch(err => {
                console.log('location offline ' + err)
                dispatch(getStoredLocation())
            })
    }

    function request() { return { type: LOCATION_REQUEST } }
    function success(location) { return { type: LOCATION_SUCCESS, location } }
}

const getStoredLocation = () => {
    return dispatch => {
        try {
            let location = store.get('LOCATION')
            if (location) {
                location.cached = true;
                dispatch(success(location))
            } else {
                dispatch(failure('NOT_STORED'))
            }
        } catch (err) {
            dispatch(failure(err))
        }
    }

    function success(location) { return { type: LOCATION_SUCCESS, location } }
    function failure(error) { return { type: STORAGE_ERROR, error } }
}


const setStoredLocation = (location) => {
    try {
        store.set('LOCATION', location)
        console.log('location storage successful')
    } catch (err) {
        console.log(err)
    }
}


export const locationActions = {
    LOCATION_REQUEST,
    LOCATION_SUCCESS,
    STORAGE_ERROR,
    FINE_ERROR,
    CRUDE_ERROR,

    getFineLocation,
    getCrudeLocation,
    getStoredLocation,
    preferStoredLocation
};


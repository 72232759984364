import 'react-app-polyfill/stable'
import 'core-js'

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify from "aws-amplify";
import config from "./config/Config";

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import configureStore from './store/Store';
import Loading from "./views/widgets/Animate"

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './i18n'

React.icons = icons


const oauth = {
  domain: config.cognito.DOMAIN,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: config.cognito.SIGNIN_REDIRECT_URL,
  redirectSignOut: config.cognito.SIGNOUT_REDIRECT_URL,
  responseType: 'code'
};

Amplify.configure({
  Auth: {
      oauth: oauth,
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      federationTarget: "COGNITO_USER_POOLS",
  },
  API: {
      endpoints: [
          {
              name: "lagna360",
              endpoint: config.apiGateway.URL,
              region: config.apiGateway.REGION,
          },
      ]
  }
});


const root = createRoot(document.getElementById('root'))
root.render(
  <React.Suspense fallback={<Loading color='primary' message={'Initializing'} />}>
    <Provider store={configureStore()}>
      <GoogleReCaptchaProvider reCaptchaKey={config.RECAPTCHA}>
        <App />
      </GoogleReCaptchaProvider>
    </Provider>
  </React.Suspense>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

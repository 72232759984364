// contexts.js

export const PREDICTIONS_CHART_ROUTE = '/predictions/chart';
export const PREDICTIONS_PERIOD_ROUTE = '/predictions/period';
export const HOROSCOPE_SHADOWS_ROUTE = '/horoscope/shadows';
export const DASHBOARD_ROUTE = '/dashboard';
export const HOME_ROUTE = '/';
export const DASHA_ROUTE = '/horoscope/dasha';
export const TRANSITS_ROUTE = '/horoscope/transits';

// chatbots.js
export const CHATBOTS = {
    KundaliReading: {
        title: 'Kundali Reading',
        logicalName: 'KundaliReading',
        route: PREDICTIONS_CHART_ROUTE,
        serverLogicalName: 'kundali_reading'
    },
    PanchangAstroscore: {
        title: 'Panchang Astroscore',
        logicalName: 'PanchangAstroscore',
        route: DASHBOARD_ROUTE,
        serverLogicalName: 'panchang_astroscore'
    },
    Home: {
        title: 'Panchang Astroscore',
        logicalName: 'PanchangAstroscore',
        route: HOME_ROUTE,
        serverLogicalName: 'panchang_astroscore'
    },
    UpagrahaShadows: {
        title: 'Upagraha Shadows',
        logicalName: 'UpagrahaShadows',
        route: HOROSCOPE_SHADOWS_ROUTE,
        serverLogicalName: 'upagraha_shadows'
    },
    PrasnaTantra: {
        title: 'Prasna Tantra',
        logicalName: 'PrasnaTantra',
        route: TRANSITS_ROUTE,
        serverLogicalName: 'prasna_tantra'
    },
    DashaAnalysis: {
        title: 'Dasha Analysis',
        logicalName: 'DashaAnalysis',
        route: DASHA_ROUTE,
        serverLogicalName: 'dasha_analysis'
    },
    GocharAnalysis: {
        title: 'Gochar Analysis',
        logicalName: 'GocharAnalysis',
        route: PREDICTIONS_PERIOD_ROUTE,
        serverLogicalName: 'gochar_analysis'
    }
};

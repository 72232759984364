
const local = {
  stripe: {
    publishableKey: "pk_test_51JwUANGHX4SIN1QhkLflNhQ7wuNIlKHePCSaGvEG6y8YIfJFnXekNV7jECY8WfxwvupTrsCOUycwc1fdnmuTJ71T00mp2uF5x5",
    pricingTableId: "prctbl_1OxDcaGHX4SIN1QhKNO9SZrG"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://m0g4zgepo5.execute-api.us-east-2.amazonaws.com/dev"
  },
  cms: {
    URL: "https://cms.lagna360.com",
    locales: '/locales',
    supportedLanguages : [
      {code: 'en', title: 'english'}, 
      {code: 'hi', title: 'हिंदी'}, 
      {code: 'fr', title: 'français'}, 
      {code: 'es', title: 'español'}, 
      {code: 'ru', title: 'русский'}, 
      {code: 'de', title: 'deutsche'}, 
      {code: 'zh', title: '普通话'}, 
      {code: 'ja', title: '日本人'}
    ],
    activeLanguages: [
      {code: 'en', title: 'english'}, 
      {code: 'hi', title: 'हिंदी'}, 
      {code: 'fr', title: 'français'}, 
      {code: 'es', title: 'español'}, 
      {code: 'ru', title: 'русский'}, 
      {code: 'de', title: 'deutsche'}, 
      {code: 'zh', title: '普通话'}, 
      {code: 'ja', title: '日本人'}
    ]
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_dlzWOkVuj",
    APP_CLIENT_ID: "53ov5n2h4bnfriquohjuo8b4d2",
    DOMAIN: 'lagna360-staging.auth.us-east-2.amazoncognito.com',
    SIGNIN_REDIRECT_URL: 'https://localhost:3000',
    SIGNOUT_REDIRECT_URL: 'https://localhost:3000'
  },
  STRIPE: {
    PUBLISHER_KEY: 'pk_test_51JwUANGHX4SIN1QhkLflNhQ7wuNIlKHePCSaGvEG6y8YIfJFnXekNV7jECY8WfxwvupTrsCOUycwc1fdnmuTJ71T00mp2uF5x5',
  },
  GTM: "GTM-PDRCKTT",
  UA: "UA-180160032-1",
  RECAPTCHA: "6LecXacmAAAAAPr-i6SJR2d1YE0USAxxf4LxcExr"
};

const dev = {
  stripe: {
    publishableKey: "pk_test_51JwUANGHX4SIN1QhkLflNhQ7wuNIlKHePCSaGvEG6y8YIfJFnXekNV7jECY8WfxwvupTrsCOUycwc1fdnmuTJ71T00mp2uF5x5",
    pricingTableId: "prctbl_1OxDcaGHX4SIN1QhKNO9SZrG"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://m0g4zgepo5.execute-api.us-east-2.amazonaws.com/dev"
  },
  cms: {
    URL: "https://cms.lagna360.com",
    locales: '/locales',
    supportedLanguages : [
      {code: 'en', title: 'english'}, 
      {code: 'hi', title: 'हिंदी'}, 
      {code: 'fr', title: 'français'}, 
      {code: 'es', title: 'español'}, 
      {code: 'ru', title: 'русский'}, 
      {code: 'de', title: 'deutsche'}, 
      {code: 'zh', title: '普通话'}, 
      {code: 'ja', title: '日本人'}
    ],
    activeLanguages: [
      {code: 'en', title: 'english'}, 
      {code: 'hi', title: 'हिंदी'}, 
    ]
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_dlzWOkVuj",
    APP_CLIENT_ID: "1qbn63fqvm1ljdrqso7autguaf",
    DOMAIN: 'lagna360-staging.auth.us-east-2.amazoncognito.com',
    SIGNIN_REDIRECT_URL: 'https://dev.lagna360.com',
    SIGNOUT_REDIRECT_URL: 'https://dev.lagna360.com'
  },
  STRIPE: {
    PUBLISHER_KEY: 'pk_test_51JwUANGHX4SIN1QhkLflNhQ7wuNIlKHePCSaGvEG6y8YIfJFnXekNV7jECY8WfxwvupTrsCOUycwc1fdnmuTJ71T00mp2uF5x5',
  },
  GTM: "GTM-PDRCKTT",
  UA: "UA-180160032-1",
  RECAPTCHA: "6LecXacmAAAAAPr-i6SJR2d1YE0USAxxf4LxcExr"
};

const prod = {
  stripe: {
    publishableKey: "pk_tesJwaGvEG6y8YIfJFnXekNV7jECY8WfxwvupTrsCOUycwc1fdnmuTJ71T00mp2uF5x5",
    pricingTableId: "prctX4SIN1QhKNO9SZrG"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.lagna360.com"
  },
  cms: {
    URL: "https://cms.lagna360.com",
    locales: '/locales',
    supportedLanguages : [
      {code: 'en', title: 'english'}, 
      {code: 'hi', title: 'हिंदी'}, 
      {code: 'fr', title: 'français'}, 
      {code: 'es', title: 'español'}, 
      {code: 'ru', title: 'русский'}, 
      {code: 'de', title: 'deutsche'}, 
      {code: 'zh', title: '普通话'}, 
      {code: 'ja', title: '日本人'}
    ],
    activeLanguages: [
      {code: 'en', title: 'english'}
    ]
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_XCxPeYh5w",
    APP_CLIENT_ID: "2bfd9b1c207cqhbuvbqiaehjas",
    DOMAIN: 'auth.lagna360.com',
    SIGNIN_REDIRECT_URL: 'https://me.lagna360.com',
    SIGNOUT_REDIRECT_URL: 'https://me.lagna360.com'
  },
  STRIPE: {
    PUBLISHER_KEY: '',
  },
  GTM: "GTM-PPHPKDB",
  UA: "UA-180160032-2",
  RECAPTCHA: "6LecXacmAAAAAPr-i6SJR2d1YE0USAxxf4LxcExr"
};


// Default to local if not set
const config = (() => {
  switch(process.env.REACT_APP_STAGE){
    case 'prod':
      return prod;
    case 'dev':
      return dev;
    default:
      return local;
  }
})();


export default config;

import { Auth } from "aws-amplify";
import API from "../../utility/API"

const USER_FETCH = 'AUTH::USER::FETCH'
const USER_SET = 'AUTH::USER::SET'
const USER_CLEAR = 'AUTH::USER::CLEAR'
const USER_PHOTO = 'AUTH::USER::PHOTO'

const RECAPTCHA_REQUEST = 'RECAPTCHA::REQUEST';
const RECAPTCHA_SUCCESS = 'RECAPTCHA::SUCCESS';
const RECAPTCHA_FAILURE = 'RECAPTCHA::FAILURE';


//automatic after login
const getCurrentUser = () => {
    return dispatch => {
        dispatch(userFetch());
        Auth.currentAuthenticatedUser().then(authUser => {
            dispatch(userSet(authUser));
        }).catch(err => {
            dispatch(userClear());
        });
    };
}

const getUserPhoto = (input) => {
    return dispatch => {

        API.getUserPhoto(input).then(url => {
            dispatch(userPhoto(url))
        }).catch(err => {
            console.log('provider photo url error')
        })

    }
}

const signOutRequest = () => {
    return dispatch => {
        Auth.signOut().then(() => {
            window.location.reload();
        })
    }
}

//reset domain/model auth object to empty
const userClear = () => {
    return { type: USER_CLEAR }
}

//reset domain/model auth object to empty
const userFetch = () => {
    return { type: USER_FETCH }
}

//populate auth object with user credentials
const userSet = (user) => {
    return { type: USER_SET, user }
}

const userPhoto = (url) => {
    return { type: USER_PHOTO, url }
}

const verifyRecaptcha = (executeRecaptcha, userAction) => {
    return async (dispatch) => {
        dispatch(request(userAction));
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            dispatch(error());
        } else {
            const token = await executeRecaptcha(userAction);
            // Send the token to  backend for verification
            API.verify(token, userAction).then(data => {
                dispatch(success(data))
            }).catch(err => {
                dispatch(error());
            })
        }
    };

    function request() { return { type: RECAPTCHA_REQUEST }; }
    function success(verification) { return { type: RECAPTCHA_SUCCESS, verification }; }
    function error() { return { type: RECAPTCHA_FAILURE }; }
};

export const authActions = {
    USER_SET,
    USER_CLEAR,
    USER_FETCH,
    USER_PHOTO,

    signOutRequest,
    getCurrentUser,
    getUserPhoto,

    RECAPTCHA_REQUEST,
    RECAPTCHA_SUCCESS,
    RECAPTCHA_FAILURE,

    verifyRecaptcha

};


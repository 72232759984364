import { chartActions } from '../actions/chartActions';
import { combineReducers } from 'redux';
import produce from 'immer';

const defaultChartState = {
  transit: {
    D1: {},
    D2: {},
    D3: {},
    D7: {},
    D9: {},
    D12: {},
    D30: {},
    metadata: {},
    isLoading: false,
    available: false,
    astroScoreChart: undefined,
    elapsedDasha: undefined,
    predictions: []
  },

  birth: {
    D1: {},
    D2: {},
    D3: {},
    D7: {},
    D9: {},
    D12: {},
    D30: {},
    charts: {},
    dasha: [],
    shadbala: undefined,
    ashtakvarga: undefined,
    metadata: {},
    planets: undefined,
    signs: undefined,
    isLoading: false,
    available: false,
    yog: undefined,
    shadows: undefined
  },

  periodanalysis: {
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    h7: undefined,
    h8: undefined,
    h9: undefined,
    h10: undefined,
    h11: undefined,
    h12: undefined,
    total: undefined,
    events: undefined,
    isLoading: false,
    calibrated: false
  },

  birthinfo: {
    location: {
      longitude: '',
      latitude: '',
      address: ''
    },
    date: {
      month: '',
      day: '',
      year: ''
    },
    time: {
      hours24: '',
      minutes: '',
      seconds: '',
      offset: ''
    },
    gender: 'O',
    complete: false,
    isLoading: false
  }
}

const defaultPredictionsState = {
  free: {
    isLoading: false,
    file: undefined
  }
}

const defaultMatchState = {
  isLoading: false,
  list: undefined
}

const matches = (state = defaultMatchState, action) => {
  switch (action.type) {
    case chartActions.MATCH_CHART_REQUEST:
      return produce(state, draft => {
        draft.isLoading = true;
      })
    case chartActions.MATCH_CHART_SUCCESS:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.list = action.match
      })
    case chartActions.MATCH_CHART_ERROR:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.list = undefined;
      })
    default:
      return state;
  }
}

const predictions = (state = defaultPredictionsState, action) => {
  switch (action.type) {
    case chartActions.PREDICTIONS_FREE_REQUEST:
      return produce(state, draft => {
        draft.free.isLoading = true;
      })
    case chartActions.PREDICTIONS_FREE_SUCCESS:
      return produce(state, draft => {
        draft.free.isLoading = false;
        draft.free.file = action.file;
      })
    case chartActions.PREDICTIONS_FREE_ERROR:
      return produce(state, draft => {
        draft.free.isLoading = false;
      })
    default:
      return state;
  }
}

const charts = (state = defaultChartState, action) => {
  switch (action.type) {
    case chartActions.TRANSIT_CHART_CLEAR:
      return produce(state, draft => {
        draft.transit = defaultChartState.transit;
      })
    case chartActions.TRANSIT_CHART_REQUEST:
      return produce(state, draft => {
        draft.transit.isLoading = true;
      })
    case chartActions.TRANSIT_CHART_SUCCESS:
      return produce(state, draft => {
        draft.transit.isLoading = false;
        draft.transit.available = true;
        draft.transit.metadata = action.native.metadata;
        draft.transit.D1 = action.native.patri.rashi;
        draft.transit.D2 = action.native.patri.saptavarga.D2;
        draft.transit.D3 = action.native.patri.saptavarga.D3;
        draft.transit.D7 = action.native.patri.saptavarga.D7;
        draft.transit.D9 = action.native.patri.saptavarga.D9;
        draft.transit.D12 = action.native.patri.saptavarga.D12;
        draft.transit.D30 = action.native.patri.saptavarga.D30;
        draft.transit.astroScoreChart = action.native.astroChart;
        draft.transit.astroScore = action.native.astroScore;
        draft.transit.elapsedDasha = action.native.elapsedDasha;
        draft.transit.predictions = action.native.currentPredictions;
        draft.transit.charts = action.native.allCharts;
        draft.transit.calibrated = action.native.calibrated;
      })
    case chartActions.TRANSIT_CHART_ERROR:
      return produce(state, draft => {
        draft.transit.isLoading = false;
        draft.transit.available = false;
      })
    case chartActions.BIRTH_CHART_CLEAR:
      return produce(state, draft => {
        draft.birth = defaultChartState.birth;
      })
    case chartActions.BIRTH_CHART_REQUEST:
      return produce(state, draft => {
        draft.birth.isLoading = true;
      })
    case chartActions.BIRTH_CHART_SUCCESS:
      return produce(state, draft => {
        draft.birth.isLoading = false;
        draft.birth.available = true;
        draft.birth.metadata = action.native.metadata;
        draft.birth.D1 = action.native.patri.rashi;
        draft.birth.D2 = action.native.patri.saptavarga.D2;
        draft.birth.D3 = action.native.patri.saptavarga.D3;
        draft.birth.D7 = action.native.patri.saptavarga.D7;
        draft.birth.D9 = action.native.patri.saptavarga.D9;
        draft.birth.D12 = action.native.patri.saptavarga.D12;
        draft.birth.D30 = action.native.patri.saptavarga.D30;
        draft.birth.dasha = action.native.dasha;
        draft.birth.shadbala = action.native.shadbala;
        draft.birth.ashtakvarga = action.native.ashtakvarga;
        draft.birth.planets = action.native.planets;
        draft.birth.charts = action.native.charts;
        draft.birth.signs = action.native.signs;
        draft.birth.yog = action.native.yog;
        draft.birth.shadows = action.native.shadows;
      })
    case chartActions.BIRTH_CHART_ERROR:
      return produce(state, draft => {
        draft.birth.isLoading = false;
        draft.birth.available = false;
      })
    case chartActions.PERIOD_ANALYSIS_REQUEST:
      return produce(state, draft => {
        draft.periodanalysis.isLoading = true;
      })
    case chartActions.PERIOD_ANALYSIS_SUCCESS:
      return produce(state, draft => {
        draft.periodanalysis.isLoading = false;
        draft.periodanalysis.h1 = action.native.h1;
        draft.periodanalysis.h2 = action.native.h2;
        draft.periodanalysis.h3 = action.native.h3;
        draft.periodanalysis.h4 = action.native.h4;
        draft.periodanalysis.h5 = action.native.h5;
        draft.periodanalysis.h6 = action.native.h6;
        draft.periodanalysis.h7 = action.native.h7;
        draft.periodanalysis.h8 = action.native.h8;
        draft.periodanalysis.h9 = action.native.h9;
        draft.periodanalysis.h10 = action.native.h10;
        draft.periodanalysis.h11 = action.native.h11;
        draft.periodanalysis.h12 = action.native.h12;
        draft.periodanalysis.total = action.native.total;
        draft.periodanalysis.events = action.native.events;
        draft.periodanalysis.calibrated = action.native.calibrated;
      })
    case chartActions.PERIOD_ANALYSIS_ERROR:
      return produce(state, draft => {
        draft.periodanalysis.isLoading = false;
      })
    case chartActions.CHART_INFO_REQUEST:
      return produce(state, draft => {
        let birthinfo = {}
        draft.birthinfo = birthinfo;
        birthinfo.isLoading = true;
      })
    case chartActions.CHART_INFO_SUCCESS:
      return produce(state, draft => {
        draft.birthinfo = action.info;
        draft.birthinfo.isLoading = false;
      })
    case chartActions.CHART_INFO_ERROR:
      return produce(state, draft => {
        let birthinfo = {}
        draft.birthinfo = birthinfo;
        birthinfo.isLoading = false;
      })
    default:
      return state
  }
}

const defaultTrainingState = {
  isLoading: false,
  status: undefined,
  lastUpdated: undefined,
  alert: {
    message: undefined,
    severity: undefined
  }
}

const training = (state = defaultTrainingState, action) => {
  switch (action.type) {
    case chartActions.PREDICTIONS_TRAINING_REQUEST:
      return produce(state, draft => {
        draft.isLoading = true;
        draft.status = 'STARTING';
        draft.alert = { message: 'Starting training...', severity: 'info' };
      });
    case chartActions.PREDICTIONS_TRAINING_SUCCESS:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.status = 'START_SUCCESS';
        draft.alert = { message: 'Training started...', severity: 'success' };
      });
    case chartActions.PREDICTIONS_TRAINING_ERROR:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.status = 'START_ERROR';
        draft.alert = { message: 'Error! try again...', severity: 'danger' };
      });
    case chartActions.TRAINING_STATUS_REQUEST:
      return produce(state, draft => {
        draft.isLoading = true;
        draft.status = 'FETCHING_STATUS';
        draft.alert = { message: 'Checking status...', severity: 'info' };
      });
    case chartActions.TRAINING_STATUS_SUCCESS:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.status = action.payload.status;
        draft.lastUpdated = action.payload.lastUpdated;
        let message = 'Training in progress.';
        let severity = 'primary';

        const date = new Date(action.payload.lastUpdated);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;

        const strTime = hours + ':' + minutes + ' ' + ampm;
        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${strTime}`;

        switch (action.payload.status) {
          case 'COMPLETED':
            message = 'Training Completed! - ' + formattedDate;
            severity = 'success';
            draft.status = 'COMPLETED';
            break;
          case 'FAILED':
            message = 'Please contact support!';
            severity = 'danger';
            draft.status = 'FAILED';
            break;
          case 'NOTFOUND':
            message = 'Run first training!';
            severity = 'info';
            draft.status = 'NOTFOUND';
            break;
          case 'STARTED':
            message = 'Training in progress..';
            severity = 'info';
            draft.status = 'STARTED';
            break;
          default:
        }

        draft.alert = { message, severity };
      });
    case chartActions.TRAINING_STATUS_ERROR:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.status = 'FETCH_ERROR';
        draft.alert = { message: 'Error fetching status!', severity: 'warning' };
      });
    default:
      return state;
  }
};

const defaultChatState = {
  messages: [],
  isLoading: false,
  error: undefined,
  question: '',
}

const chat = (state = defaultChatState, action) => {
  switch (action.type) {
    case chartActions.CHAT_MESSAGE_REQUEST:
      return produce(state, draft => {
        draft.isLoading = true;
        draft.messages = action.messages;
      })
    case chartActions.CHAT_MESSAGE_RESPONSE:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.messages = action.data.messages;
        draft.question = '';
        draft.error = undefined;
      })
    case chartActions.CHAT_MESSAGE_ERROR:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.question = draft.messages[draft.messages.length - 1].content;
        draft.messages.pop();
        draft.error = action.error;
      })
    case chartActions.UPDATE_CHAT_QUESTION:
      return produce(state, draft => {
        draft.question = action.question;
      })
    case chartActions.CHAT_HISTORY_CLEAR:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.messages = [];
        draft.error = undefined;
        draft.question = '';
      })
    default:
      return state;
  }
}

const Combined = combineReducers({
  charts, predictions, matches, training, chat
})

export default Combined

import API from "../../utility/API";
import { prefActions } from './prefActions';

const GET_LIFE_EVENTS_REQUEST = 'GET::LIFE::EVENT::REQUEST';
const GET_LIFE_EVENTS_SUCCESS = 'GET::LIFE::EVENT::SUCCESS';
const GET_LIFE_EVENTS_ERROR = 'GET::LIFE::EVENT::ERROR';

const DELETE_LIFE_EVENT_REQUEST = 'DELETE::LIFE::EVENT::REQUEST';
const DELETE_LIFE_EVENT_SUCCESS = 'DELETE::LIFE::EVENT::SUCCESS';
const DELETE_LIFE_EVENT_ERROR = 'DELETE::LIFE::EVENT::ERROR';

const ADD_LIFE_EVENT_REQUEST = 'ADD::LIFE::EVENT::REQUEST';
const ADD_LIFE_EVENT_SUCCESS = 'ADD::LIFE::EVENT::SUCCESS';
const ADD_LIFE_EVENT_ERROR = 'ADD::LIFE::EVENT::ERROR';

const PROCESS_ZODIAC_SURVEY_REQUEST = 'SURVEY::ZODIAC::REQUEST';
const PROCESS_ZODIAC_SURVEY_SUCCESS = 'SURVEY::ZODIAC::SUCCESS';
const PROCESS_ZODIAC_SURVEY_ERROR = 'SURVEY::ZODIAC::ERROR';

const RESTART_ZODIAC_SURVEY_REQUEST = 'SURVEY::ZODIAC::RESTART:REQUEST';
const RESTART_ZODIAC_SURVEY_SUCCESS = 'SURVEY::ZODIAC::RESTART:SUCCESS';
const RESTART_ZODIAC_SURVEY_ERROR = 'SURVEY::ZODIAC::RESTART:ERROR';

const BIODATA_SAVE_REQUEST = 'BIODATA::SAVE::REQUEST';
const BIODATA_SAVE_SUCCESS = 'BIODATA::SAVE::SUCCESS';
const BIODATA_SAVE_ERROR = 'BIODATA::SAVE::ERROR';

const BIODATA_FETCH_REQUEST = 'BIODATA::FETCH::REQUEST';
const BIODATA_FETCH_SUCCESS = 'BIODATA::FETCH::SUCCESS';
const BIODATA_FETCH_ERROR = 'BIODATA::FETCH::ERROR';


const getAllUserEvents = () => {
    return dispatch => {
        dispatch(request())
        API.getAllUserEvents().then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: GET_LIFE_EVENTS_REQUEST } }
    function success(info) { return { type: GET_LIFE_EVENTS_SUCCESS, info } }
    function failure(error) { return { type: GET_LIFE_EVENTS_ERROR, error } }
}

const deleteUserEvent = (id) => {
    return dispatch => {
        dispatch(request())
        API.deleteUserEvent(id).then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(id));
        }).finally(() => dispatch(getAllUserEvents()))
    };

    function request(id) { return { type: DELETE_LIFE_EVENT_REQUEST, id } }
    function success(info) { return { type: DELETE_LIFE_EVENT_SUCCESS, info } }
    function failure(id) { return { type: DELETE_LIFE_EVENT_ERROR, id } }
}

const addUserEvent = (event) => {
    return dispatch => {
        dispatch(request())
        API.addUserEvent(event).then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        }).finally(() => dispatch(getAllUserEvents()))
    };

    function request() { return { type: ADD_LIFE_EVENT_REQUEST } }
    function success(info) { return { type: ADD_LIFE_EVENT_SUCCESS, info } }
    function failure(error) { return { type: ADD_LIFE_EVENT_ERROR, error } }
}

const processZodiacSurvey = (sv, answer, rebuild) => {
    return dispatch => {
        dispatch(request())
        API.processZodiacSurvey(sv, answer, rebuild).then(data => {
            dispatch(success(data));
            dispatch(prefActions.getPreferences());
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: PROCESS_ZODIAC_SURVEY_REQUEST } }
    function success(info) { return { type: PROCESS_ZODIAC_SURVEY_SUCCESS, info } }
    function failure(error) { return { type: PROCESS_ZODIAC_SURVEY_ERROR, error } }
}

const restartZodiacSurvey = () => {
    return dispatch => {
        dispatch(request())
        API.resetZodiacSurvey().then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: RESTART_ZODIAC_SURVEY_REQUEST } }
    function success(info) { return { type: RESTART_ZODIAC_SURVEY_SUCCESS, info } }
    function failure(error) { return { type:RESTART_ZODIAC_SURVEY_ERROR, error } }
}

const saveBiodata = (bio) => {
    return dispatch => {
        dispatch(request(bio))
        API.saveUserBiodata(bio).then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request(data) { return { type: BIODATA_SAVE_REQUEST, data } }
    function success(data) { return { type: BIODATA_SAVE_SUCCESS, data } }
    function failure(error) { return { type: BIODATA_SAVE_ERROR, error } }
}

const fetchBiodata = () => {
    return dispatch => {
        dispatch(request())
        API.getUserBiodata().then(data => {
            dispatch(success(data));
        }).catch(err => {
            dispatch(failure(err));
        })
    };

    function request() { return { type: BIODATA_FETCH_REQUEST } }
    function success(data) { return { type: BIODATA_FETCH_SUCCESS, data } }
    function failure(error) { return { type: BIODATA_FETCH_ERROR, error } }
}


export const calibrationActions = {
    GET_LIFE_EVENTS_REQUEST,
    GET_LIFE_EVENTS_SUCCESS,
    GET_LIFE_EVENTS_ERROR, 
    DELETE_LIFE_EVENT_REQUEST,
    DELETE_LIFE_EVENT_SUCCESS,
    DELETE_LIFE_EVENT_ERROR,
    ADD_LIFE_EVENT_REQUEST,
    ADD_LIFE_EVENT_SUCCESS,
    ADD_LIFE_EVENT_ERROR,

    getAllUserEvents,
    deleteUserEvent,
    addUserEvent,

    PROCESS_ZODIAC_SURVEY_REQUEST,
    PROCESS_ZODIAC_SURVEY_SUCCESS,
    PROCESS_ZODIAC_SURVEY_ERROR,
    RESTART_ZODIAC_SURVEY_REQUEST,
    RESTART_ZODIAC_SURVEY_SUCCESS,
    RESTART_ZODIAC_SURVEY_ERROR,

    BIODATA_SAVE_REQUEST,
    BIODATA_SAVE_SUCCESS,
    BIODATA_SAVE_ERROR,
    BIODATA_FETCH_REQUEST,
    BIODATA_FETCH_SUCCESS,
    BIODATA_FETCH_ERROR,

    processZodiacSurvey,
    restartZodiacSurvey,
    saveBiodata,
    fetchBiodata
};
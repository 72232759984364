import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

let centerStyle = {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}

const Loading = ({color}) => {
    return (
        <div style={centerStyle}>
            <FontAwesomeIcon icon={faSpinner} pulse className={`fa-3x d-flex text-center align-items-center justify-content-center text-${color}`}/>
        </div>
    )
}
export default Loading

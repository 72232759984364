
import API from "../../utility/API"

const BILLING_PRICES_FETCH = 'BILLING::PRICES::REQUEST'
const BILLING_PRICES_SUCCESS = 'BILLING::PRICES::SUCCESS'
const BILLING_PRICES_ERROR = 'BILLING::PRICES::ERROR'

const BILLING_STATUS_FETCH = 'BILLING::STATUS::REQUEST'
const BILLING_STATUS_SUCCESS = 'BILLING::STATUS::SUCCESS'
const BILLING_STATUS_ERROR = 'BILLING::STATUS::ERROR'

const BILLING_QUOTA_UPDATE = 'BILLING::QUOTA::UPDATE'


const getPrices = () => {

    return dispatch => {
        dispatch(request())
        API.getPriceData().then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: BILLING_PRICES_FETCH } }
    function error() { return { type: BILLING_PRICES_ERROR } }
    function success(billing) { return { type: BILLING_PRICES_SUCCESS, billing } }
}

const getStatus = () => {
    return dispatch => {
        dispatch(request())
        API.getBillingStatus().then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: BILLING_STATUS_FETCH } }
    function error() { return { type: BILLING_STATUS_ERROR } }
    function success(status) { return { type: BILLING_STATUS_SUCCESS, status } }
}

const updateQuota = (quota) => {
    return dispatch => {
        dispatch(request(quota))
    };
    function request(quota) { return { type: BILLING_QUOTA_UPDATE, quota } }
}


export const billingActions = {
    BILLING_PRICES_FETCH,
    BILLING_PRICES_SUCCESS,
    BILLING_PRICES_ERROR,

    BILLING_STATUS_FETCH,
    BILLING_STATUS_SUCCESS,
    BILLING_STATUS_ERROR,

    BILLING_QUOTA_UPDATE,

    getPrices,
    getStatus,
    updateQuota
};


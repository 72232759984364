
import API from "../../utility/API"

const PREFERENCES_SAVE_REQUEST = 'PREFERENCES::SAVE::REQUEST'
const PREFERENCES_SAVE_SUCCESS = 'PREFERENCES::SAVE::SUCCESS'
const PREFERENCES_SAVE_ERROR = 'PREFERENCES::SAVE::ERROR'

const PREFERENCES_GET_REQUEST = 'PREFERENCES::GET::REQUEST'
const PREFERENCES_GET_SUCCESS = 'PREFERENCES::GET::SUCCESS'
const PREFERENCES_GET_ERROR = 'PREFERENCES::GET::ERROR'


const setPreferences = (preferences) => {
    return dispatch => {
        dispatch(request())
        API.setUserPreferences(preferences).then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: PREFERENCES_SAVE_REQUEST } }
    function error() { return { type: PREFERENCES_SAVE_ERROR } }
    function success(prefs) { return { type: PREFERENCES_SAVE_SUCCESS, prefs } }
}

const getPreferences = () => {
    return dispatch => {
        dispatch(request())
        API.getUserPreferences().then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error());
        })
    };

    function request() { return { type: PREFERENCES_GET_REQUEST } }
    function error() { return { type: PREFERENCES_GET_ERROR } }
    function success(prefs) { return { type: PREFERENCES_GET_SUCCESS, prefs } }
}


export const prefActions = {
    PREFERENCES_SAVE_REQUEST,
    PREFERENCES_SAVE_SUCCESS,
    PREFERENCES_SAVE_ERROR,

    PREFERENCES_GET_REQUEST,
    PREFERENCES_GET_SUCCESS,
    PREFERENCES_GET_ERROR,

    setPreferences,
    getPreferences
};


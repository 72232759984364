import { combineReducers } from 'redux';

import authReducer from './authReducer';
import locationReducer from './locationReducer';
import chartReducer from './chartReducer';
import uiReducer from './uiReducer';
import dashaReducer from './dashaReducer';
import socialReducer from './socialReducer';
import rectificationReducer from './rectificationReducer';
import calibrationReducer from './calibrationReducer';
import prefReducer from './prefReducer';
import adminReducer from './adminReducer';
import billingReducer from './billingReducer';


const createRootReducer = () => combineReducers({
    location: locationReducer,
    auth: authReducer,
    astro: chartReducer,
    ui: uiReducer,
    dasha: dashaReducer,
    social: socialReducer,
    rectification: rectificationReducer,
    calibration: calibrationReducer,
    preferences: prefReducer,
    admin: adminReducer,
    billing: billingReducer
})

export default createRootReducer
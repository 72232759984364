import React from 'react';
import { CButton, CRow, CCol } from '@coreui/react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import API from "../../../utility/API";

const UpgradeSubscriptionPrompt = ({ onClose }) => {
    const navigate = useNavigate();
    const billingStatus = useSelector(state => state.billing.billingStatus);
    const { subscription, active } = billingStatus;

    const gotoPortal = async () => {
        try {
            window.dataLayer.push({
                event: 'UpgradeToTurboClicked'
            });
            const { url } = await API.createPortalSession();
            if (url) window.location.href = url;
        } catch (err) {
            console.error("Error creating portal session:", err);
        }
    };

    const renderContent = () => {
        if (!active || !subscription?.paymentDetails?.planType) {
            return {
                title: 'Unlock the power of lagna360',
                description: 'With AI Chatbot, ask any question about your chart and receive personalized, in-depth analysis.'
            };
        } else if (subscription?.paymentDetails?.planType === 'PLUS' && Number(subscription.quota) === 0) {
            return {
                title: 'Upgrade to Turbo Plan',
                description: "You've used up your quota for the Plus plan. Upgrade to the Turbo plan for more AI Chatbot usage."
            };
        } else if (subscription?.paymentDetails?.planType === 'TURBO' && Number(subscription.quota) === 0) {
            return {
                title: 'Quota Exhausted',
                description: "We're sorry, but you've used up your quota for the Turbo plan. You'll have more quota in the next billing cycle."
            };
        }
    };
    const { title, description } = renderContent();

    const handleClick = () => {
        if (active && subscription?.paymentDetails?.planType === 'PLUS' && Number(subscription.quota) === 0) {
            gotoPortal();
        } else {
            onClose();
            navigate('/billing/pricing');
        }
    }

    const getButtonText = () => {
        if (active && subscription?.paymentDetails?.planType === 'PLUS' && Number(subscription.quota) === 0) {
            return 'Upgrade Now';
        } else {
            return 'View Pricing';
        }
    }

    return (

        <div className='p-4'>
            <h5 className='mb-3'>{title}</h5>
            <p>{description}.</p>
            <CRow className='mt-5'>
                <CCol xs='8' className='text-start'>
                    <CButton className='w-100' color='success' onClick={handleClick}>{getButtonText()}</CButton>
                </CCol>
                <CCol xs='4' className='text-end'>
                    <CButton color='dark' variant='outline' onClick={() => onClose()}>Later</CButton>
                </CCol>
            </CRow>
        </div>

    );
};

export default UpgradeSubscriptionPrompt;
import { billingActions } from '../actions/billingActions';
import produce from 'immer';
import { combineReducers } from 'redux';


const defaultPrices = {
    list: [],
    userCurrency: 'USD',
    isLoading: true,
    userCountry: 'US'
}

const defaultBillingStatus = {
    isLoading: true,
    active: false,
    subscription: undefined
};

const billingStatus = (state = defaultBillingStatus, action) =>
    produce(state, draft => {
        switch (action.type) {
            case billingActions.BILLING_STATUS_FETCH:
                draft.isLoading = true;
                break;

            case billingActions.BILLING_STATUS_SUCCESS:
                draft.isLoading = false;
                draft.active = action.status.accessStatus;
                draft.subscription = action.status;
                break;

            case billingActions.BILLING_STATUS_ERROR:
                draft.isLoading = false;
                draft.active = false;
                draft.subscription = {};
                break;

            case billingActions.BILLING_QUOTA_UPDATE:
                draft.subscription.quota = action.quota;
                break;
            default:
                return state
        }
    });



const pricing = (state = defaultPrices, action) => {
    switch (action.type) {
        case billingActions.BILLING_PRICES_FETCH:
            return produce(state, draft => {
                draft.isLoading = true;
            })
        case billingActions.BILLING_PRICES_SUCCESS:
            return produce(state, draft => {
                draft.isLoading = false;
                draft.list = action.billing.list;
                draft.userCurrency = action.billing.userCurrency;
                draft.userCountry = action.billing.userCountry;
            })
        case billingActions.BILLING_PRICES_ERROR:
            return produce(state, draft => {
                draft.isLoading = false;
            })
        default:
            return state
    }
}


const Combined = combineReducers({
    billingStatus, pricing
})

export default Combined
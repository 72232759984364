import API from "../../utility/API"

const FRIENDS_GET_REQUEST = 'SOCIAL::FRIENDS::GET_REQUEST'
const FRIENDS_GET_ERROR = 'SOCIAL::FRIENDS:GET_ERROR'
const FRIENDS_GET_SUCCESS = 'SOCIAL::FRIENDS::GET_SUCCESS'

const CONTACTS_GMAIL_REQUEST = 'SOCIAL::CONTACTS::GMAIL_REQUEST'
const CONTACTS_GMAIL_ERROR = 'SOCIAL::CONTACTS::GMAIL_ERROR'
const CONTACTS_GMAIL_SUCCESS = 'SOCIAL::CONTACTS::GMAIL_SUCCESS'

const CONTACTS_GMAIL_TOGGLE_ALL = 'SOCIAL::CONTACTS::GMAIL_TOGGLE_ALL'
const CONTACTS_GMAIL_TOGGLE_ONE = 'SOCIAL::CONTACTS::GMAIL_TOGGLE_ONE'
const CONTACTS_GMAIL_SEND_ONE = 'SOCIAL::CONTACTS::GMAIL_SEND_ONE'

const CONTACTS_GMAIL_FILTER = 'SOCIAL::CONTACTS::GMAIL_FILTER'

const FRIENDS_REQUEST_SUCCESS = 'SOCIAL::CONTACTS::FRIENDS_REQUEST_SUCCESS'
const FRIENDS_REQUEST_ERROR = 'SOCIAL::CONTACTS::FRIENDS_REQUEST_ERROR'

const FRIENDS_ACCEPT_SUCCESS = 'SOCIAL::CONTACTS::FRIENDS_ACCEPT_SUCCESS'
const FRIENDS_ACCEPT_ERROR = 'SOCIAL::CONTACTS::FRIENDS_ACCEPT_ERROR'

const processGmailContacts = (contacts) => {
    return dispatch => {
        dispatch(request())
        API.processEmailContacts(contacts).then(data => {
            dispatch(success(data))
            getUserFriends()
        }).catch(err => {
            dispatch(error(err));
        })
    };

    function request() { return { type: CONTACTS_GMAIL_REQUEST } }
    function error() { return { type: CONTACTS_GMAIL_ERROR } }
    function success(processed) { return { type: CONTACTS_GMAIL_SUCCESS, processed } }
}

const toggleAllGmailContacts = () => {
    return { type: CONTACTS_GMAIL_TOGGLE_ALL }
}

const toggleOneGmailContact = (index) => {
    return { type: CONTACTS_GMAIL_TOGGLE_ONE, index }
}

const sendOneGmailContact = (index) => {
    return { type: CONTACTS_GMAIL_SEND_ONE, index }
}

const filterGmailContacts = (include) => {
    return { type: CONTACTS_GMAIL_FILTER, include }
}

const getUserFriends = () => {
    return dispatch => {
        dispatch(request())
        API.getUserFriends().then(data => {
            dispatch(success(data))
        }).catch(err => {
            dispatch(error(err));
        })
    };

    function request() { return { type: FRIENDS_GET_REQUEST } }
    function error() { return { type: FRIENDS_GET_ERROR } }
    function success(list) { return { type: FRIENDS_GET_SUCCESS, list } }
}

const sendFriendRequest = (m) => {
    return dispatch => {
        API.sendFriendRequest(m).then(status => {
            console.log(status)
            dispatch(getUserFriends())
            dispatch(success())
        }).catch(err => {
            console.log(err)
            dispatch(error());
        })
    }
    function error() { return { type: FRIENDS_REQUEST_ERROR } }
    function success() { return { type: FRIENDS_REQUEST_SUCCESS } }
}

const acceptFriendRequest = (m) => {
    return dispatch => {
        API.acceptFriendRequest(m).then(status => {
            console.log(status)
            dispatch(getUserFriends())
            dispatch(success())
        }).catch(err => {
            console.log(err)
            dispatch(error());
        })
    }
    function error() { return { type: FRIENDS_ACCEPT_ERROR } }
    function success() { return { type: FRIENDS_ACCEPT_SUCCESS } }
}

const removeFriend = (m) => {
    return dispatch => {
        API.deleteUserFriend(m).then(status => {
            console.log(status)
            dispatch(getUserFriends())
        }).catch(err => {
            console.log(err)
        })
    }
}

export const socialActions = {
    FRIENDS_GET_REQUEST,
    FRIENDS_GET_ERROR,
    FRIENDS_GET_SUCCESS,

    CONTACTS_GMAIL_REQUEST,
    CONTACTS_GMAIL_ERROR,
    CONTACTS_GMAIL_SUCCESS,
    CONTACTS_GMAIL_TOGGLE_ALL,
    CONTACTS_GMAIL_TOGGLE_ONE,
    CONTACTS_GMAIL_SEND_ONE,
    CONTACTS_GMAIL_FILTER,

    FRIENDS_REQUEST_SUCCESS,
    FRIENDS_REQUEST_ERROR,
    FRIENDS_ACCEPT_SUCCESS,
    FRIENDS_ACCEPT_ERROR,

    processGmailContacts,
    toggleAllGmailContacts,
    toggleOneGmailContact,
    filterGmailContacts,
    sendOneGmailContact,

    getUserFriends,
    sendFriendRequest,
    acceptFriendRequest,
    removeFriend,
};
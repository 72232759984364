import React from 'react';
import CIcon from '@coreui/icons-react';
import { cilChatBubble } from '@coreui/icons';

class ChatButton extends React.Component {
  render() {
    const { onClick, show } = this.props;
    if (!show) return null;
    return (
      <>
        <style>
          {`
            .chat-button {
              width: 50px;
              height: 50px;
              border-radius: 25px;
              background-color: #007bff;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: width 0.3s ease; /* Keep this for the width */
              cursor: pointer;
              color: white;
              font-size: 24px;
              position: fixed;
              bottom: 20px;
              right: 20px;
              box-shadow: 0 4px 6px rgba(0,0,0,0.1);
              padding: 0 10px;
              overflow: hidden;
            }

            .chat-button:hover {
              width: 200px;
            }

            .chat-button-icon {
              flex-shrink: 0;
            }

            .chat-button-text {
              display: none;
              opacity: 0;
              transition: display 0s, opacity 0.3s ease 0.3s; /* Delay the opacity transition */
              padding-right: 15px;
              padding-left: 10px;
              white-space: nowrap;
            }

            .chat-button:hover .chat-button-text {
              display: inline;
              opacity: 1;
              transition: display 0s, opacity 0.3s ease; /* Start opacity transition immediately on hover */
            }
          `}
        </style>
        <div className="chat-button"  onClick={onClick}>
          <CIcon icon={cilChatBubble} size='xl' className="chat-button-icon"/>
          <span className="chat-button-text">AI Chat</span>
        </div>
      </>
    );
  }
}

export default ChatButton;

import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFDownloadLink } from '@react-pdf/renderer';

Font.register({
    family: 'OpenSans',
    src: 'https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf'
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: 40, // Consider a more substantial padding for A4 format
        fontFamily: 'OpenSans', // Use the registered font
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
    },
    paragraph: {
        fontSize: 12,
        marginBottom: 10,
        lineHeight: 1.5,
        textAlign: 'justify',
    },
    section: {
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
    },
    userQuestion: {
        fontSize: 16,
        marginBottom: 10,
        lineHeight: 1.5,
        textAlign: 'justify',
    },
});

// Create Document Component
const MyDocument = ({ messages, title }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.title}>{title}</Text>
                {messages.map((msg, index) => (
                    msg.role === 'user' ? (
                        <Text key={index} style={styles.userQuestion}>
                            {msg.content}
                        </Text>
                    ) : (
                        <Text key={index} style={styles.paragraph}>
                            {msg.content}
                        </Text>
                    )
                ))}
            </View>
        </Page>
    </Document>
);

// React component for downloading the PDF
const DownloadPDFButton = ({ messages, isLoading, title }) => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
    const fileName = `chat_${formattedDate}.pdf`;
    const isDisabled = isLoading || !messages || messages.length === 0;
    return (
        <PDFDownloadLink document={<MyDocument messages={messages} title={title}/>} 
            fileName={fileName}
            style={{
                padding: '10px 10px',
                margin: '3px',
                backgroundColor: isDisabled ? '#cccccc' : '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '20px',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                fontSize: '16px',
                width: '12em',
                textAlign: 'center',
                textDecoration: 'none',
                display: 'block',
                pointerEvents: isDisabled ? 'none' : 'auto'
            }}
        >
            {({ loading }) =>
                loading ? (
                    'Loading document...'
                ) : (
                    'Download Chat'
                )
            }
        </PDFDownloadLink>
    );
};

export default DownloadPDFButton;

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavTitle,
  CImage,
  CNavItem,
  CSidebarToggler, CBadge
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import { cilApplicationsSettings, cilBirthdayCake, cilSun, cilBolt, cilCalendar, cilCommentBubble, cilCopy, cilEnvelopeLetter, cilGrain, cilGraph, cilInputPower, cilMoon, cilNotes, cilSpeedometer, cilChatBubble, cilClone, cilHeart } from '@coreui/icons'

import { useTranslation } from 'react-i18next';

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import { uiActions } from '../store/actions/uiActions';

const TheSidebar = () => {
  const { t } = useTranslation(['calculations']);
  const dispatch = useDispatch()

  const sidebarNarrow = useSelector((state) => state.ui.dashboard.sidebarNarrow)
  const sidebarShow = useSelector((state) => state.ui.dashboard.sidebarShow)
  const setSidebarShow = (value) => dispatch(uiActions.setSidebarShow(value))
  const setSidebarNarrow = (value) => dispatch(uiActions.setSidebarNarrow(value))
  const birth_info = useSelector(state => state.astro.charts.birthinfo)

  const LagnaSidebar = () => {
    return (
      <>
        <CSidebarBrand href="https://www.lagna360.com">
          <CImage
            className="sidebar-brand-full"
            src='/images/banner-dark.png'
            alt="logo"
            height="32"
          />
          <CImage
            className="sidebar-brand-narrow"
            src='/images/logo-dark.png'
            alt="logo"
            height="32"
          />
        </CSidebarBrand>
        <CSidebarNav>
          <SimpleBar>
            <CNavItem component={NavLink} to='/dashboard' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilSpeedometer} />{t('route.dashboard')}
            </CNavItem>

            <CNavTitle>{t('route.category.predictions')}</CNavTitle>
            <CNavItem component={NavLink} to='/predictions/chat' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilChatBubble} />{t('route.chatPredictions')}<CBadge color="success ms-auto">New</CBadge>
            </CNavItem>
            <CNavItem component={NavLink} to='/predictions/chart' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilNotes} />{t('route.chartPredictions')}
            </CNavItem>
            <CNavItem component={NavLink} to="/predictions/period" disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilCalendar} />{t('route.periodPredictions')}
            </CNavItem>
            <CNavItem component={NavLink} to="/predictions/match" disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilClone} />{t('route.samhitaPredictions')}
            </CNavItem>

            <CNavTitle>{t('route.category.tools')}</CNavTitle>
            <CNavItem component={NavLink} to='/tools/rectification' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilBirthdayCake} />{t('route.rectification')}
            </CNavItem>
            <CNavItem component={NavLink} to='/tools/calibration/events' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilBolt} />{t('route.calibration.events')}
            </CNavItem>
            <CNavItem component={NavLink} to='/tools/calibration/zodiac' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilSun} />{t('route.calibration.zodiac')}
            </CNavItem>
            <CNavItem component={NavLink} to='/tools/calibration/biodata' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilHeart} />{t('route.calibration.bio')}
            </CNavItem>

            <CNavTitle>{t('route.category.calculations')}</CNavTitle>
            <CNavItem component={NavLink} to='/horoscope/sv' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilApplicationsSettings} />{t('route.SV')}
            </CNavItem>
            <CNavItem component={NavLink} to='/horoscope/av' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilGraph} />{t('route.AV')}
            </CNavItem>
            <CNavItem component={NavLink} to='/horoscope/sb' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilInputPower} />{t('route.SB')}
            </CNavItem>
            <CNavItem component={NavLink} to='/horoscope/dasha' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilMoon} />{t('route.dasha')}
            </CNavItem>
            <CNavItem component={NavLink} to='/horoscope/shadows' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilCopy} />{t('route.shadows')}
            </CNavItem>
            <CNavItem component={NavLink} to='/horoscope/transits' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilGrain} />{t('route.transits')}
            </CNavItem>
            <CNavTitle>{t('route.category.social')}</CNavTitle>
            <CNavItem component={NavLink} to='/share' disabled={!birth_info || birth_info.complete === false}>
              <CIcon customClassName="nav-icon" icon={cilCommentBubble} />{t('route.share')}
            </CNavItem>
            <CNavTitle>{t('route.category.communication')}</CNavTitle>
            <CNavItem disabled={true} component={NavLink} to='/' onClick={(e) => e.preventDefault()}>
              <CIcon customClassName="nav-icon" icon={cilEnvelopeLetter} />{'support@lagna360.com'}
            </CNavItem>
          </SimpleBar>
        </CSidebarNav>
      </>
    )
  }

  return (
    <>
      <CSidebar
        className='d-lg-none'
        position="fixed"
        visible={sidebarShow}
        onVisibleChange={(visible) => { setSidebarShow(visible) }}
      >
        <LagnaSidebar />
      </CSidebar>

      <CSidebar
        className='d-none d-lg-flex'
        position="fixed"
        overlaid={false}
        narrow={sidebarNarrow}
        visible={sidebarShow}
      >
        <LagnaSidebar />

        <CSidebarToggler
          className="d-none d-md-flex"
          onClick={() => setSidebarNarrow(!sidebarNarrow)}
        />
      </CSidebar>
    </>
  )
}

export default React.memo(TheSidebar)

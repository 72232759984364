import React from 'react';
import { CModal, CModalBody } from '@coreui/react';
import styled from 'styled-components';
import ChartAnalysisBot from './ChartAnalysisBot';
import UpgradeSubscriptionPrompt from './UpgradeSubscriptionPrompt';

const StyledCModal = styled(CModal)`
  max-height: 100vh;
  mx-width: 100vw;
  padding: 0;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; // Firefox
  -ms-overflow-style: none;

  & .modal {
    max-height: 100vh;
    mx-width: 100vw;
    padding: 0;
    overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none;
  }

  & .modal-dialog {
    max-height: 100vh;
    mx-width: 100vw;
    padding: 0;
    overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none;
  }

  & .modal-content {
    max-height: 100vh;
    mx-width: 100vw;
    padding: 0;
    overflow: hidden !important;
    &::-webkit-scrollbar {
      display: none;
    }
  
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none;
  }

  & .modal-body {
    max-height: 100vh;
    mx-width: 100vw;
    padding: 0;
    overflow: hidden !important;
    &::-webkit-scrollbar {
      display: none;
    }
  
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none;
  }
`;

const ChatModal = ({ showSalesPitch, isOpen, onClose, context }) => {
  return (
    <StyledCModal visible={isOpen} alignment="center" fullscreen='sm' backdrop="static" keyboard={false}>

      <CModalBody>
        {showSalesPitch ?
          <UpgradeSubscriptionPrompt
            onClose={onClose}
          />
          :
          <ChartAnalysisBot
            onClose={onClose}
            context={context}
          />
        }
      </CModalBody>

    </StyledCModal>
  );
};

export default ChatModal;